import {Alarm} from "../../../../interfaces/Alarm";
import {AlarmDetail} from "./AlarmDetail";

type AlarmsListProps = {
  alarms: Alarm[];
};

export function AlarmsList(props: AlarmsListProps) {
  return (
    <>
      {props.alarms.map((alarm, index) => {
        return <AlarmDetail key={index} alarm={alarm} />;
      })}
    </>
  );
}
