import {Bar, getElementAtEvent} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import {Card, useTheme} from "@mui/material";
import {useRef} from "react";
import {eventLevelColor} from "../../plant/AlarmService";
import {useAppDispatch} from "../../../../store/hooks";
import {
  setAlarmSelected,
  setOpen,
} from "../../../../store/scada/plants/AlarmSelectedSlice";
import {CurrentAlarmItem} from "../../../../interfaces/CurrentAlarmItem";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
);

type CurrentAlarmItemRenamed = CurrentAlarmItem & {
  alarmTypeReferenceRenamed: string;
};

type CountAlarmChartProps = {
  value: Array<CurrentAlarmItemRenamed>;
  title: string;
};

type AlarmType = {
  alarmTypeLevel: number;
  alarmTypeLabel: string;
  alarmTypeReference: string;
  alarmTypeReferenceRenamed: string;
};

type CountAlarmChartItem = AlarmType & {
  nbrAlarms: number;
  alarmTypeReferenceRenamed: string;
};

function countAlarms(
  data: Array<CurrentAlarmItemRenamed>,
): CountAlarmChartItem[] {
  const uniqueAlarmType: Set<AlarmType> = new Set();
  const res: CountAlarmChartItem[] = [];
  const alarmTypeReferences: string[] = [];
  data.forEach((obj) => {
    if (!alarmTypeReferences.includes(obj.alarmTypeReference)) {
      const row = {
        alarmTypeReference: obj.alarmTypeReference,
        alarmTypeLevel: obj.alarmTypeLevel,
        alarmTypeLabel: obj.alarmTypeLabel,
        alarmTypeReferenceRenamed: obj.alarmTypeReferenceRenamed,
      };
      uniqueAlarmType.add(row);
      alarmTypeReferences.push(obj.alarmTypeReference);
    }
  });
  uniqueAlarmType.forEach((obj) => {
    const nbrAlarms = data.filter(
      (x) => x.alarmTypeLabel === obj.alarmTypeLabel,
    ).length;
    res.push({
      nbrAlarms: nbrAlarms,
      alarmTypeLevel: obj.alarmTypeLevel,
      alarmTypeLabel: obj.alarmTypeLabel,
      alarmTypeReference: obj.alarmTypeReference,
      alarmTypeReferenceRenamed: obj.alarmTypeReferenceRenamed,
    });
  });

  return res;
}

export default function CountAlarmChart(props: CountAlarmChartProps) {
  const theme = useTheme();
  const chartRef = useRef();
  const dispatch = useAppDispatch();

  const countAlarmsByReference = countAlarms(props.value);

  // sort data in descending order
  const sortedData = countAlarmsByReference.sort(
    (x, y) => y.nbrAlarms - x.nbrAlarms,
  );

  function onClick(
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  ): void {
    if (!chartRef.current) {
      return;
    }
    const element = getElementAtEvent(chartRef.current, event);
    if (element.length === 0) {
      return;
    }
    dispatch(setAlarmSelected(sortedData[element[0].index].alarmTypeReference));
    dispatch(setOpen(true));
  }

  const backgroundColors = sortedData.map((x) =>
    eventLevelColor(x.alarmTypeLevel, theme),
  );

  const labels = sortedData.map((x) => x.alarmTypeReferenceRenamed);
  const data = {
    labels,
    datasets: [
      {
        data: sortedData.map((x) => x.nbrAlarms),
        backgroundColor: backgroundColors,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      title: {
        display: true,
        text: `${props.title}`,
        font: {
          size: 16,
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        display: function (context) {
          const biggestAlarm = sortedData[0].nbrAlarms;
          const value = context.dataset.data[context.dataIndex] as number;
          return value > biggestAlarm * 0.05;
        },
        font: {
          weight: "bold",
          size: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return sortedData[tooltipItem.dataIndex].alarmTypeLabel;
          },
          afterLabel: function (tooltipItem) {
            return tooltipItem.dataset.data[tooltipItem.dataIndex]?.toString();
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
      },
    },
  };
  const height = `${50 + labels.length * 15}px`;
  return (
    <Card className="scada-card" style={{marginBottom: 0}}>
      <Bar
        options={options}
        data={data}
        ref={chartRef}
        plugins={[ChartDataLabels]}
        onClick={onClick}
        style={{maxHeight: height, height: height, marginRight: "10px"}}
      />
    </Card>
  );
}
