import {useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import apiDirectus from "../../../service/api/ApiDirectus";

import {FileSearch} from "../../../interfaces/documentation/FileSearch";

import ConfiguratorCard from "./ConfiguratorCard";
import SoftwareCard from "./SoftwareCard";

import {Folder as FolderInterface} from "../../../interfaces/documentation/Folder";
import {ConfigurationCard} from "../../../interfaces/documentation/ConfigurationCard";

import CustomNoRowsOverlay from "../../../component/CustomNoRowsOverlay";

import {Paper, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SoftwareView() {
  const ID_PRINCIPAL_SOFTWARE_FOLDER = "b344d965-f16b-472c-b701-a6297d108fb6";

  const [searchSoftware, setSearchSoftware] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [displayConfigurator, setDisplayConfigurator] = useState<boolean>(true);
  const [configuration] = useState<ConfigurationCard>({
    title: "Configurateur",
    description:
      "Ce logiciel permet de mettre en route les trackers Okwind et Lumioo.",
    tags: ["Mise en route", "Okwind", "Lumioo"],
  });

  const [folders, setFolders] = useState<Array<FolderInterface>>([]);

  const [files, setFiles] = useState<Array<Array<FileSearch>>>([]);
  const [filesFilter, setFilesFilter] = useState<Array<Array<FileSearch>>>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const clear = () => {
    setSearchSoftware("");
    setFilesFilter(files);
    setDisplayConfigurator(true);
  };

  const filterFile = (
    file: FileSearch | ConfigurationCard,
    search: string,
  ): FileSearch | ConfigurationCard | null => {
    let matchFile = null;

    if (
      file.title.toLowerCase().includes(search) ||
      (file.description && file.description.toLowerCase().includes(search)) ||
      file.tags.some((str: string) => str.toLowerCase().includes(search))
    ) {
      matchFile = file;
    } else {
      matchFile = null;
    }

    if ("type" in file) {
      setDisplayConfigurator(matchFile !== null);
    }

    return matchFile;
  };

  const onChange = (searchValue: string) => {
    setSearchSoftware(searchValue);
    if (searchValue.length < 1) {
      setFilesFilter(files);
    } else {
      setFilesFilter(
        files.filter((file) => {
          let matchFile = null;
          file.forEach((element) => {
            if (element.type !== "image/png") {
              matchFile = filterFile(element, searchValue);
            }
          });
          return matchFile;
        }),
      );
      filterFile(configuration, searchValue); // filter uniq on configurator card
    }
  };

  useEffect(() => {
    folders.forEach((element: FolderInterface) => {
      if (element.parent === ID_PRINCIPAL_SOFTWARE_FOLDER) {
        apiDirectus.getFilesFromFolder(element.id).then((currentFiles) => {
          setFiles((files) => [...files, currentFiles]);
          setFilesFilter((files) => [...files, currentFiles]);
          setLoading(false);
        });
      }
    });
  }, [folders]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await apiDirectus.getAllFolders().then((data) => {
        setFolders(data);
      });
    };
    fetchData();
  }, []);

  return (
    <Grid container sx={{backgroundColor: "transparent"}}>
      <Card className="search-card" style={{marginBottom: "10px"}}>
        <Grid
          container
          justifyContent="space-between"
          sx={{backgroundColor: "transparent"}}>
          <Grid item xs={12} sm={12} md={6} lg={9}>
            <TextField
              id="searchInput"
              style={{marginTop: 15, width: isMobile ? "100%" : 325}}
              label="Rechercher"
              value={searchSoftware}
              placeholder="Par nom, tags, description..."
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(event.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchSoftware !== "" && (
                  <InputAdornment position="end">
                    <IconButton onClick={clear}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Card>

      {loading ? (
        <Card
          className="custom-card"
          sx={{marginLeft: "-10px", backgroundColor: "transparent"}}>
          <CustomNoRowsOverlay noRowText="" loading={loading} />
        </Card>
      ) : (
        <div>
          <Grid container sx={{backgroundColor: "transparent"}}>
            {displayConfigurator && (
              <Grid item xs={12} md={6} lg={4} style={{padding: "10px"}}>
                <ConfiguratorCard
                  configuration={configuration}></ConfiguratorCard>
              </Grid>
            )}
            {filesFilter.map((currentFiles) => (
              <Grid
                key={currentFiles[0].id}
                item
                xs={12}
                md={6}
                lg={4}
                style={{padding: "10px"}}>
                <SoftwareCard files={currentFiles}></SoftwareCard>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {filesFilter.length === 0 && !displayConfigurator && (
        <Card className="custom-card">
          <Grid container sx={{backgroundColor: "transparent", pl: 0, pr: 0}}>
            <Grid item xs={12} style={{padding: "10px"}}>
              <Paper
                sx={{width: "100%", overflow: "hidden", boxShadow: "none"}}>
                <CustomNoRowsOverlay
                  noRowText="Aucun logiciel trouvé avec cette recherche"
                  loading={null}
                />
              </Paper>
            </Grid>
          </Grid>
        </Card>
      )}
    </Grid>
  );
}
