import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {HttpRequestHeader} from "antd/es/upload/interface";
import sizeaApi, {DATE_FORMAT_ZULU} from "../../../service/api/ApiSizea";
import Simulation, {
  ConsumptionFileTypeRef,
  DateOptionRef,
  HotWaterTankTypeRef,
} from "./model";
import {v4 as uuidv4} from "uuid";
import {Upload} from "antd";
import {SelectImmersionHeaterType} from "./component/SelectImmersionHeaterType";
import ToastService from "../../../service/ToastService";
import {UploadChangeParam} from "antd/es/upload";
import {SelectTrackerType} from "./component/SelectTrackerType";
import DownloadIcon from "@mui/icons-material/Download";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {useSearchParams} from "react-router-dom";
import {SelectConverterType} from "./component/SelectConverterType";
import {SelectBatteryType} from "./component/SelectBatteryType";
import {SelectEssType} from "./component/SelectEssType";
import {PlaceholderDialog} from "./component/PlaceholderDialog";
import {SelectTemplate} from "./component/SelectTemplate";
import AutocompleteAddress from "../../../component/form/AutocompleteAddress";
import {convertKilo} from "../../../utils/utils";
import {TrackerType} from "../../../interfaces/TrackerType";
import MapAddress from "../../../component/form/MapAddress";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";

export default function SizeaForm() {
  const [searchParams] = useSearchParams();
  const converterTypes = useSelector(
    (state: RootState) => state.converterTypes,
  );
  const batteryTypes = useSelector((state: RootState) => state.batteryTypes);

  const getDefaultValues = () => {
    const consumptionFileType = searchParams.get("consumptionFileTypeEnum");
    const consumptionFileTypeEnum: ConsumptionFileTypeRef = Object.values(
      ConsumptionFileTypeRef,
    ).includes(consumptionFileType as ConsumptionFileTypeRef)
      ? (consumptionFileType as ConsumptionFileTypeRef)
      : ConsumptionFileTypeRef.SIZEA;

    const dateOptionEnum =
      consumptionFileTypeEnum === ConsumptionFileTypeRef.ENEDIS
        ? DateOptionRef.MANUAL
        : DateOptionRef.AUTO;

    // Init productionInput avec 1 par défaut si aucun tracker n'est renseigné
    const trackers = searchParams.get("trackers")
      ? JSON.parse(searchParams.get("trackers") as string)
      : [[1, null, 0]];
    const trackerInputs = trackers.map((tracker: number[]) => {
      return {
        trackerQuantity: tracker[0],
        trackerTypeId: tracker[1],
        customRatedPower: convertKilo(tracker[2], 3),
      };
    });

    // Init stockage thermique, vide si aucun n'est renseigné
    const hotWaterRequirements = searchParams.get("hotWaterRequirements")
      ? JSON.parse(searchParams.get("hotWaterRequirements") as string)
      : [];
    const waterPointInputs = hotWaterRequirements.map(
      (hotWaterRequirement: (number | string)[]) => {
        return {
          uuid: uuidv4(),
          hotWaterTankType: hotWaterRequirement[0],
          hotWaterRequirementFileUuid: hotWaterRequirement[1],
          volumeCuve: hotWaterRequirement[2],
          targetTemperature: hotWaterRequirement[3],
          immersionHeaterQuantity: hotWaterRequirement[4],
          immersionHeaterTypeId: hotWaterRequirement[5],
        };
      },
    );

    // Init ESS list, vide si aucun n'est renseigné
    const essList = searchParams.get("essList")
      ? JSON.parse(searchParams.get("essList") as string)
      : [];
    const essInputs = essList.map((ess: (number | string)[]) => {
      return {
        rescuedReserve: ess[0],
        essTypeId: ess[1],
        converterTypeId: ess[2],
        nbConverter: ess[3],
        batteryTypeId: ess[4],
        nbBattery: ess[5],
      };
    });

    return {
      uuid: uuidv4(),
      plantInput: {
        timezone: "europe/paris",
        consumptionFileUuid: searchParams.get("consumptionFileUuid") ?? null,
        consumptionFileTypeEnum: consumptionFileTypeEnum,
        dateOptionEnum: dateOptionEnum,
        startDate: searchParams.get("startDate") ?? null,
        endDate: searchParams.get("endDate") ?? null,
        name: searchParams.get("name") ?? "",
        address: searchParams.get("address") ?? "",
        latitude: searchParams.get("latitude")
          ? parseFloat(searchParams.get("latitude") as string)
          : undefined,
        longitude: searchParams.get("longitude")
          ? parseFloat(searchParams.get("longitude") as string)
          : undefined,
        mapImageFileUuid: null,
      },
      isCustom: searchParams.get("isCustom")
        ? searchParams.get("isCustom") === "1"
        : false,
      template: searchParams.get("template") ?? null,
      customTemplate: searchParams.get("customTemplate") ?? null,
      productionInput: {
        isRestricted: searchParams.get("isRestricted")
          ? searchParams.get("isRestricted") === "1"
          : undefined,
        trackerInputs: trackerInputs,
      },
      waterPointInputs: waterPointInputs,
      essInputs: essInputs,
    };
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: {errors},
  } = useForm<Simulation>({
    defaultValues: getDefaultValues(),
  });

  const {
    fields: fieldsTrackers,
    append: appendTrackers,
    remove: removeTrackers,
  } = useFieldArray({
    control,
    name: "productionInput.trackerInputs",
  });

  const watchFieldTrackersArray = watch("productionInput.trackerInputs");
  const controlledFieldsTrackers = fieldsTrackers.map(
    (fieldsTrackers, index) => {
      return {
        ...fieldsTrackers,
        ...watchFieldTrackersArray[index],
      };
    },
  );

  const {
    fields: fieldsHotWaterRequirements,
    append: appendHotWaterRequirements,
    remove: removeHotWaterRequirements,
  } = useFieldArray({
    control,
    name: "waterPointInputs",
  });
  const watchFieldHotWaterRequirementsArray = watch("waterPointInputs");
  const controlledFieldsHotWaterRequirements = fieldsHotWaterRequirements.map(
    (fieldsHotWaterRequirements, index) => {
      return {
        ...fieldsHotWaterRequirements,
        ...watchFieldHotWaterRequirementsArray[index],
      };
    },
  );

  const {
    fields: fieldsEssList,
    append: appendEssList,
    remove: removeEssList,
  } = useFieldArray({
    control,
    name: "essInputs",
  });
  const watchFieldEssArray = watch("essInputs");
  const controlledFieldsEssList = fieldsEssList.map((fieldsEssList, index) => {
    return {
      ...fieldsEssList,
      ...watchFieldEssArray[index],
    };
  });

  const uploadOnChange = function (param: UploadChangeParam) {
    if (param.file === undefined) return;
    if (param.file.error === undefined) return;
    if (param.file.response === undefined) return;

    // Erreur générique
    let violation = param.file.response.detail;

    // Sinon on affiche l'erreur API PLATFORM
    if (param.file.response["hydra:description"] !== undefined) {
      violation = param.file.response["hydra:description"];
    }

    ToastService.error(violation);
  };

  const uploadTemplateOnChange = function (param: UploadChangeParam) {
    uploadOnChange(param);

    if (param.file?.response) {
      const response = param.file?.response;
      // filename généré par le serveur
      const filename = response.fileName;
      setValue("customTemplate", filename);
    }
  };

  const onSubmit: SubmitHandler<Simulation> = (data) => {
    // chaque simulation a son propre id
    data.uuid = uuidv4();

    data.plantInput.dateOptionEnum =
      watch(`plantInput.consumptionFileTypeEnum`) ===
      ConsumptionFileTypeRef.ENEDIS
        ? DateOptionRef.MANUAL
        : DateOptionRef.AUTO;

    data.isCustom = watch(`template`) === "custom";
    if (data.isCustom) {
      if (data.customTemplate === null) {
        ToastService.error("Veuillez téléverser un fichier modèle");
        return;
      }
      data.template = data.customTemplate;
    }

    data.productionInput.trackerInputs = data.productionInput.trackerInputs.map(
      (trackerInput) => {
        trackerInput.customRatedPower = trackerInput.customRatedPower * 1000; // repasser ici en Watts pour le serveur
        return trackerInput;
      },
    );

    sizeaApi.simulate(data).then(() => {
      ToastService.success(
        "Votre demande a été prise en compte, la simulation vous sera envoyée par mail dans les 30 minutes à venir",
      );
    });
  };

  const handleTrackerChange = function (
    trackerType: TrackerType,
    index: number,
  ) {
    const floatingRatedPower = convertKilo(trackerType.defaultRatedPower, 3);
    setValue(
      `productionInput.trackerInputs.${index}.customRatedPower`,
      floatingRatedPower,
    );
  };

  const handlePositionChange = ({lat, lng}: {lat: number; lng: number}) => {
    setValue("plantInput.latitude", lat);
    setValue("plantInput.longitude", lng);
  };

  const handleMapCapture = (mapCanvas: HTMLCanvasElement) => {
    const mapImageUuid = uuidv4();
    const mapImageFilename = mapImageUuid + ".png";

    mapCanvas.toBlob(function (blob) {
      if (!blob) {
        return;
      }

      sizeaApi.uploadMapImage(mapImageFilename, blob).then(() => {
        setValue(`plantInput.mapImageFileUuid`, mapImageUuid);
      });
    }, "image/png");
  };

  const getTotalPower = (
    converterTypeId: string,
    nbConverter: number,
  ): string => {
    const type = converterTypes.data.find(
      (converterType) => converterType.id === converterTypeId,
    );
    if (type === undefined) return "";

    const totalPower = nbConverter * type.ratedPower;
    return new Intl.NumberFormat("fr-FR", {
      useGrouping: true,
    }).format(totalPower);
  };

  const getTotalCapacity = (
    batteryTypeId: string,
    nbBattery: number,
  ): string => {
    const type = batteryTypes.data.find(
      (batteryType) => batteryType.id === batteryTypeId,
    );
    if (type === undefined) return "";

    const totalPower = nbBattery * type.ratedCapacity;
    return new Intl.NumberFormat("fr-FR", {
      useGrouping: true,
    }).format(totalPower);
  };

  return (
    <Card
      style={{margin: "auto", borderRadius: "8px", boxShadow: "none"}}
      sx={{width: {xs: "100%", md: "80%", lg: "65%"}}}>
      <CardContent>
        <Grid container justifyContent={"center"} direction={"row"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <Stack spacing={2}>
                <FormGroup>
                  <TextField
                    required
                    error={!!errors.plantInput?.name}
                    label="Nom installation"
                    {...register("plantInput.name", {required: true})}
                  />
                </FormGroup>
                <Divider textAlign="left">Implantation</Divider>
                <FormControl style={{marginBottom: "0px"}} fullWidth>
                  <Controller
                    name={`plantInput.address`}
                    control={control}
                    render={({field}) => (
                      <AutocompleteAddress
                        {...field}
                        label="Adresse"
                        required={true}
                        preloadvalue={watch(`plantInput.address`)}
                        register={register}
                        options={{required: true}}
                        setValue={setValue}
                        trigger={trigger}
                        address={`plantInput.address`}
                        latitude={`plantInput.latitude`}
                        longitude={`plantInput.longitude`}
                        error={!!errors.plantInput?.address}
                        latLngPrecision={3}
                        doNotLoadGoogleMapScript
                      />
                    )}
                  />
                </FormControl>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}>
                  <FormGroup>
                    <TextField
                      required
                      type="number"
                      inputProps={{
                        step: "0.001",
                      }}
                      label="Latitude"
                      error={!!errors.plantInput?.latitude}
                      {...register("plantInput.latitude", {
                        required: true,
                        valueAsNumber: true,
                      })}
                      helperText="3 chiffres après la virgule"
                      value={watch("plantInput.latitude") || ""}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      required
                      type="number"
                      inputProps={{
                        step: "0.001",
                      }}
                      label="Longitude"
                      error={!!errors.plantInput?.longitude}
                      {...register("plantInput.longitude", {
                        required: true,
                        valueAsNumber: true,
                      })}
                      helperText="3 chiffres après la virgule"
                      value={watch("plantInput.longitude") || ""}
                    />
                  </FormGroup>
                </Stack>

                <FormControl>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <MapAddress
                      latitude={watch("plantInput.latitude")}
                      longitude={watch("plantInput.longitude")}
                      onChange={handlePositionChange}
                      precision={3}
                      mapWidth="550px"
                      mapHeight="331px"
                      onCapture={handleMapCapture}
                    />
                  </Box>
                </FormControl>

                <Divider textAlign="left">
                  Consommation{" "}
                  <Tooltip title="Exemple format Sizea">
                    <Button
                      id="consumption-example-download"
                      onClick={() => {
                        sizeaApi.getExampleFile("consumptions-example");
                      }}>
                      <DownloadIcon />
                    </Button>
                  </Tooltip>
                </Divider>

                <FormControl>
                  <FormLabel required id="file-type-label">
                    Type de fichier
                  </FormLabel>
                  <Controller
                    rules={{required: true}}
                    control={control}
                    name={`plantInput.consumptionFileTypeEnum`}
                    render={({field}) => (
                      <RadioGroup
                        row
                        {...field}
                        id={`consumptionFileTypeEnum-select`}
                        style={{marginLeft: 15}}
                        defaultValue={watch(
                          "plantInput.consumptionFileTypeEnum",
                        )}>
                        <FormControlLabel
                          value={ConsumptionFileTypeRef.ENEDIS}
                          control={<Radio />}
                          label="Courbe de Charge Brut"
                        />
                        <FormControlLabel
                          value={ConsumptionFileTypeRef.SIZEA}
                          control={<Radio />}
                          label="Courbe de Charge Modifiée / PM"
                        />
                        <FormControlLabel
                          value={ConsumptionFileTypeRef.NO_CONSUMPTION}
                          control={<Radio />}
                          label="Pas de consommation"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>

                {watch(`plantInput.consumptionFileTypeEnum`) !==
                  ConsumptionFileTypeRef.SIZEA && ( // pour ENEDIS et NO_CONSUMPTION, les dates de début et fin sont obligatoires. Pour SIZEA, on ne mentionne pas de dates du tout
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}>
                    <FormGroup>
                      <Controller
                        control={control}
                        name={`plantInput.startDate`}
                        rules={{
                          required:
                            watch(`plantInput.consumptionFileTypeEnum`) ===
                            ConsumptionFileTypeRef.ENEDIS,
                        }}
                        render={({
                          field: {ref, onBlur, name, onChange, ...field},
                          fieldState,
                        }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"fr"}>
                            <DatePicker
                              {...field}
                              inputRef={ref}
                              label="Du"
                              onChange={(event) => {
                                if (null !== event) {
                                  onChange(
                                    dayjs(event).utc().format(DATE_FORMAT_ZULU),
                                  );
                                }
                              }}
                              renderInput={(inputProps) => (
                                <TextField
                                  {...inputProps}
                                  onBlur={onBlur}
                                  name={name}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Controller
                        control={control}
                        name={`plantInput.endDate`}
                        rules={{
                          required:
                            watch(`plantInput.consumptionFileTypeEnum`) ===
                            ConsumptionFileTypeRef.ENEDIS,
                        }}
                        render={({
                          field: {ref, onBlur, name, onChange, ...field},
                          fieldState,
                        }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"fr"}>
                            <DatePicker
                              {...field}
                              inputRef={ref}
                              label="Au"
                              onChange={(event) => {
                                if (null !== event) {
                                  onChange(
                                    dayjs(event).utc().format(DATE_FORMAT_ZULU),
                                  );
                                }
                              }}
                              renderInput={(inputProps) => (
                                <TextField
                                  {...inputProps}
                                  onBlur={onBlur}
                                  name={name}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </FormGroup>
                  </Stack>
                )}

                {watch(`plantInput.consumptionFileTypeEnum`) !==
                  ConsumptionFileTypeRef.NO_CONSUMPTION && (
                  <Upload
                    name="file"
                    data={{
                      identifier:
                        "consumption-" +
                        watch("plantInput.consumptionFileUuid"),
                      timezone: watch("plantInput.timezone"),
                      consumptionFileTypeEnum: watch(
                        "plantInput.consumptionFileTypeEnum",
                      ),
                      dateOptionEnum:
                        watch(`plantInput.consumptionFileTypeEnum`) ===
                        ConsumptionFileTypeRef.ENEDIS
                          ? DateOptionRef.MANUAL
                          : DateOptionRef.AUTO,
                      startDate: watch("plantInput.startDate"),
                      endDate: watch("plantInput.endDate"),
                    }}
                    headers={
                      sizeaApi.getUploadHeaders() as unknown as HttpRequestHeader
                    }
                    onChange={uploadOnChange}
                    beforeUpload={() => {
                      setValue("plantInput.consumptionFileUuid", uuidv4());
                    }}
                    accept=".xlsx, .csv"
                    action={sizeaApi.getUploadUrl()}
                    listType="picture-card"
                    showUploadList={true}
                    maxCount={1}
                    style={{fontFamily: "Montserrat"}}
                    defaultFileList={
                      watch("plantInput.consumptionFileUuid")
                        ? [
                            {
                              uid:
                                watch("plantInput.consumptionFileUuid") || "",
                              name: "Fichier de consommation indiqué par l'URL",
                            },
                          ]
                        : []
                    }>
                    Déposez vos fichiers ici
                  </Upload>
                )}
                <Divider textAlign="left">Production</Divider>
                {watch(`plantInput.consumptionFileTypeEnum`) !==
                  ConsumptionFileTypeRef.NO_CONSUMPTION && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={watch("productionInput.isRestricted")}
                        {...register("productionInput.isRestricted")}
                      />
                    }
                    label="Bridage"
                    labelPlacement="end"
                  />
                )}
                <List>
                  {controlledFieldsTrackers.map((item, index) => {
                    return (
                      <ListItem key={item.id}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={2}>
                            <FormGroup>
                              <TextField
                                type="number"
                                required
                                error={
                                  !!errors.productionInput?.trackerInputs?.[
                                    index
                                  ]?.trackerQuantity
                                }
                                label="Nbr tracker"
                                defaultValue={item.trackerQuantity}
                                {...register(
                                  `productionInput.trackerInputs.${index}.trackerQuantity` as const,
                                  {required: true, valueAsNumber: true},
                                )}
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel required id="tracker-type-label">
                                Ref tracker
                              </InputLabel>
                              <SelectTrackerType
                                labelId="tracker-type-label"
                                label={"Ref tracker"} // masqué, mais prépare une place quand le label est shrinked, et évite que la ligne passe à travers du label
                                error={
                                  !!errors.productionInput?.trackerInputs?.[
                                    index
                                  ]?.trackerTypeId
                                }
                                name={`productionInput.trackerInputs.${index}.trackerTypeId`}
                                register={register}
                                defaultValue={item.trackerTypeId}
                                onTrackerChange={(trackerType: TrackerType) =>
                                  handleTrackerChange(trackerType, index)
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={3}>
                            <Tooltip
                              title={
                                <Box>
                                  <Box>
                                    La puissance crête nominale peut parfois
                                    être supérieure à celle par défaut selon le
                                    type des panneaux approvisionnés.
                                  </Box>
                                  <Box sx={{marginTop: 2}}>
                                    Avant d’ajuster cette valeur vous-même,
                                    assurez-vous que les panneaux souhaités
                                    pourront bien être fournis au client à la
                                    date cible. Il est de votre responsabilité
                                    de fournir une étude avec des informations
                                    fiables.
                                  </Box>
                                </Box>
                              }>
                              <FormGroup>
                                <TextField
                                  type="number"
                                  required
                                  error={
                                    !!errors.productionInput?.trackerInputs?.[
                                      index
                                    ]?.customRatedPower
                                  }
                                  label="Puissance crête (kW)"
                                  defaultValue={item.customRatedPower}
                                  inputProps={{
                                    step: "0.1",
                                  }}
                                  {...register(
                                    `productionInput.trackerInputs.${index}.customRatedPower` as const,
                                    {required: true, valueAsNumber: true},
                                  )}
                                />
                              </FormGroup>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip title="Retirer cette unité de production">
                            <Button
                              id={`removeTracker-${index}`}
                              variant="contained"
                              color="secondary"
                              sx={{
                                visibility:
                                  watch(`productionInput.trackerInputs`)
                                    .length > 1
                                    ? "visible"
                                    : "hidden",
                              }}
                              onClick={() => removeTrackers(index)}>
                              <RemoveCircleRoundedIcon />
                            </Button>
                          </Tooltip>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
                <Tooltip title="Ajouter une unité de production">
                  <Button
                    sx={{width: "64px"}}
                    id={"addTrackerButton"}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      appendTrackers({
                        trackerQuantity: 1,
                        trackerTypeId: "",
                        customRatedPower: 0,
                      });
                    }}>
                    <AddCircleIcon />
                  </Button>
                </Tooltip>

                {watch(`plantInput.consumptionFileTypeEnum`) !==
                  ConsumptionFileTypeRef.NO_CONSUMPTION && (
                  <>
                    <Divider textAlign="left">
                      Stockage thermique - MEA HEAT
                    </Divider>
                    <List>
                      {controlledFieldsHotWaterRequirements.map(
                        (item, index) => {
                          return (
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              key={item.id}
                              sx={{maxWidth: 770}}>
                              <Grid
                                item
                                xs={11}
                                sx={{
                                  border: "1px dashed grey",
                                  borderRadius: "7px",
                                  marginBottom: "40px",
                                }}>
                                <ListItem>
                                  <List>
                                    <FormControl>
                                      <FormLabel required id="storage-label">
                                        Type d'installation
                                      </FormLabel>
                                      <Controller
                                        rules={{required: true}}
                                        control={control}
                                        name={`waterPointInputs.${index}.hotWaterTankType`}
                                        render={({field}) => (
                                          <RadioGroup
                                            row
                                            {...field}
                                            id={`hotWaterTankType-select-${index}`}
                                            style={{marginLeft: 15}}
                                            defaultValue={watch(
                                              `waterPointInputs.${index}.hotWaterTankType`,
                                            )}>
                                            <FormControlLabel
                                              value={HotWaterTankTypeRef.BALLON}
                                              control={<Radio />}
                                              label="Ballon"
                                            />
                                            <FormControlLabel
                                              value={HotWaterTankTypeRef.CUVE}
                                              control={<Radio />}
                                              label="Cuve"
                                            />
                                          </RadioGroup>
                                        )}
                                      />
                                    </FormControl>

                                    <TextField
                                      required={
                                        watch(
                                          `waterPointInputs.${index}.hotWaterTankType`,
                                        ) === "CUVE"
                                      }
                                      label="Volume cuve"
                                      sx={{
                                        m: 1,
                                        width: "25%",
                                        visibility:
                                          watch(
                                            `waterPointInputs.${index}.hotWaterTankType`,
                                          ) === "CUVE"
                                            ? "visible"
                                            : "hidden",
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            Litre
                                          </InputAdornment>
                                        ),
                                      }}
                                      {...register(
                                        `waterPointInputs.${index}.volumeCuve`,
                                        {
                                          required:
                                            watch(
                                              `waterPointInputs.${index}.hotWaterTankType`,
                                            ) === "CUVE",
                                          valueAsNumber: true,
                                        },
                                      )}
                                    />
                                    <ListItemText key="1">
                                      1. Besoin eau chaude{" "}
                                      <Tooltip title="Exemple format Sizea">
                                        <Button
                                          id="hot-water-requirements-example-download"
                                          onClick={() => {
                                            sizeaApi.getExampleFile(
                                              "hot-water-requirements-example",
                                            );
                                          }}>
                                          <DownloadIcon />
                                        </Button>
                                      </Tooltip>
                                    </ListItemText>
                                    <ListItem key="2">
                                      <Upload
                                        className={`hotWaterRequirement-${index}-upload`}
                                        name="file"
                                        accept=".xlsx"
                                        action={sizeaApi.getUploadUrl()}
                                        headers={
                                          sizeaApi.getUploadHeaders() as unknown as HttpRequestHeader
                                        }
                                        onChange={uploadOnChange}
                                        data={{
                                          identifier: `hotwater-${watch(`waterPointInputs.${index}.hotWaterRequirementFileUuid`)}`,
                                          timezone: watch(
                                            "plantInput.timezone",
                                          ),
                                          dateOptionEnum:
                                            watch(
                                              `plantInput.consumptionFileTypeEnum`,
                                            ) === ConsumptionFileTypeRef.ENEDIS
                                              ? DateOptionRef.MANUAL
                                              : DateOptionRef.AUTO,
                                          startDate: watch(
                                            "plantInput.startDate",
                                          ),
                                          endDate: watch("plantInput.endDate"),
                                        }}
                                        beforeUpload={() => {
                                          setValue(
                                            `waterPointInputs.${index}.hotWaterRequirementFileUuid`,
                                            uuidv4(),
                                          );
                                        }}
                                        listType="picture-card"
                                        showUploadList={true}
                                        maxCount={1}
                                        style={{fontFamily: "Montserrat"}}
                                        defaultFileList={
                                          watch(
                                            `waterPointInputs.${index}.hotWaterRequirementFileUuid`,
                                          )
                                            ? [
                                                {
                                                  uid:
                                                    watch(
                                                      `waterPointInputs.${index}.hotWaterRequirementFileUuid`,
                                                    ) || "",
                                                  name: "Fichier de besoin en eau chaude indiqué par l'URL",
                                                },
                                              ]
                                            : []
                                        }>
                                        Déposez vos fichiers ici
                                      </Upload>
                                    </ListItem>
                                    <ListItemText key="3">
                                      2. Consigne de température
                                    </ListItemText>
                                    <ListItem key="4">
                                      <Grid container>
                                        <Grid item xs={3}>
                                          <TextField
                                            required
                                            type="number"
                                            label="Température"
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  °C
                                                </InputAdornment>
                                              ),
                                            }}
                                            {...register(
                                              `waterPointInputs.${index}.targetTemperature`,
                                              {
                                                required: true,
                                                valueAsNumber: true,
                                              },
                                            )}
                                          />
                                        </Grid>
                                      </Grid>
                                    </ListItem>
                                    <ListItemText key="5">
                                      3. Produits à installer
                                    </ListItemText>
                                    <ListItem key="6">
                                      <Grid container>
                                        <FormControl>
                                          <InputLabel
                                            required
                                            id="immersion-heater-label">
                                            Ref thermoplongeur
                                          </InputLabel>
                                          <SelectImmersionHeaterType
                                            sx={{
                                              minWidth: 300,
                                              maxWidth: 300,
                                            }}
                                            hotwatertanktype={watch(
                                              `waterPointInputs.${index}.hotWaterTankType`,
                                            )}
                                            control={control}
                                            error={
                                              !!errors.waterPointInputs?.[index]
                                                ?.hotWaterTankType
                                            }
                                            labelId="immersion-heater-label"
                                            label={"Ref thermoplongeur"} // masqué, mais prépare une place quand le label est shrinked, et évite que la ligne passe à travers du label
                                            name={`waterPointInputs.${index}.immersionHeaterTypeId`}
                                            register={register}
                                            setValue={setValue}
                                            defaultValue={
                                              item.immersionHeaterTypeId
                                            }
                                          />
                                        </FormControl>
                                        <FormGroup sx={{marginLeft: 1}}>
                                          <TextField
                                            required
                                            type="number"
                                            label="Nombre"
                                            {...register(
                                              `waterPointInputs.${index}.immersionHeaterQuantity`,
                                              {
                                                required: true,
                                                valueAsNumber: true,
                                              },
                                            )}
                                          />
                                        </FormGroup>
                                      </Grid>
                                    </ListItem>
                                  </List>
                                </ListItem>
                              </Grid>
                              <Grid item xs={1}>
                                <Tooltip title="Retirer ce stockage thermique">
                                  <Button
                                    id={`removeHotWaterRequirement-${index}`}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() =>
                                      removeHotWaterRequirements(index)
                                    }>
                                    <RemoveCircleRoundedIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          );
                        },
                      )}
                    </List>
                    <Tooltip title="Ajouter un stockage thermique">
                      <Button
                        sx={{width: "64px"}}
                        variant="contained"
                        color="secondary"
                        id={"addWaterPointButton"}
                        onClick={() => {
                          appendHotWaterRequirements({
                            uuid: uuidv4(),
                            hotWaterTankType: HotWaterTankTypeRef.BALLON,
                            immersionHeaterQuantity: 1,
                            immersionHeaterTypeId: "",
                            volumeCuve: null,
                            targetTemperature: 70,
                            hotWaterRequirementFileUuid: null,
                          });
                        }}>
                        <AddCircleIcon />
                      </Button>
                    </Tooltip>
                  </>
                )}
                <Divider textAlign="left">ESS</Divider>
                <List>
                  {controlledFieldsEssList.map((item, index) => {
                    return (
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        key={item.id}
                        sx={{maxWidth: 770}}>
                        <Grid
                          item
                          xs={11}
                          sx={{
                            border: "1px dashed grey",
                            borderRadius: "7px",
                            marginBottom: "40px",
                          }}>
                          <List>
                            <ListItem>
                              <FormControl>
                                <FormLabel required id="storage-label">
                                  Ess
                                </FormLabel>
                                <Grid
                                  container
                                  alignItems="center"
                                  spacing={3}
                                  sx={{paddingTop: "5px"}}>
                                  <Grid item xs={10}>
                                    <FormGroup>
                                      <TextField
                                        type="number"
                                        inputProps={{
                                          step: 100,
                                        }}
                                        required
                                        error={
                                          !!errors.essInputs?.[index]
                                            ?.rescuedReserve
                                        }
                                        label="Réserve secourue (Wh)"
                                        defaultValue={item.rescuedReserve}
                                        {...register(
                                          `essInputs.${index}.rescuedReserve` as const,
                                          {
                                            required: true,
                                            valueAsNumber: true,
                                          },
                                        )}
                                      />
                                    </FormGroup>
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </ListItem>
                            <ListItem>
                              <FormControl>
                                <Grid container alignItems="center" spacing={3}>
                                  <Grid item xs={5}>
                                    <FormControl>
                                      <InputLabel
                                        required
                                        id="ess-type-label"
                                        defaultValue={item.essTypeId}>
                                        Ref Type ESS
                                      </InputLabel>
                                      <SelectEssType
                                        error={
                                          !!errors.essInputs?.[index]?.essTypeId
                                        }
                                        sx={{minWidth: 200}}
                                        control={control}
                                        name={`essInputs.${index}.essTypeId`}
                                        defaultValue={item.essTypeId}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </ListItem>
                            <ListItem>
                              <FormControl>
                                <FormLabel required id="storage-label">
                                  Convertisseurs
                                </FormLabel>
                                <Grid container>
                                  <FormControl>
                                    <InputLabel
                                      required
                                      id="converter-type-label">
                                      Ref convertisseur
                                    </InputLabel>
                                    <SelectConverterType
                                      error={
                                        !!errors.essInputs?.[index]
                                          ?.converterTypeId
                                      }
                                      sx={{minWidth: 300, maxWidth: 300}}
                                      control={control}
                                      name={`essInputs.${index}.converterTypeId`}
                                      defaultValue={item.converterTypeId}
                                    />
                                  </FormControl>
                                  <FormGroup sx={{marginLeft: 1}}>
                                    <TextField
                                      type="number"
                                      inputProps={{
                                        min: 1,
                                        max: 2,
                                      }}
                                      required
                                      error={
                                        !!errors.essInputs?.[index]?.nbConverter
                                      }
                                      label="Nbr convertisseurs"
                                      defaultValue={item.nbConverter}
                                      {...register(
                                        `essInputs.${index}.nbConverter` as const,
                                        {required: true, valueAsNumber: true},
                                      )}
                                    />
                                  </FormGroup>
                                  <Box>
                                    Permet de convertir un maximum de{" "}
                                    {getTotalPower(
                                      item.converterTypeId,
                                      item.nbConverter,
                                    )}
                                    W de production solaire.
                                  </Box>
                                </Grid>
                              </FormControl>
                            </ListItem>
                            <ListItem>
                              <FormControl>
                                <FormLabel required id="storage-label">
                                  Batteries
                                </FormLabel>
                                <Grid container>
                                  <FormControl>
                                    <InputLabel
                                      required
                                      id="battery-type-label">
                                      Ref batterie
                                    </InputLabel>
                                    <SelectBatteryType
                                      error={
                                        !!errors.essInputs?.[index]
                                          ?.batteryTypeId
                                      }
                                      sx={{minWidth: 300, maxWidth: 300}}
                                      control={control}
                                      name={`essInputs.${index}.batteryTypeId`}
                                      defaultValue={item.batteryTypeId}
                                    />
                                  </FormControl>
                                  <FormGroup sx={{marginLeft: 1}}>
                                    <TextField
                                      type="number"
                                      inputProps={{
                                        min: 1,
                                        max: 10,
                                      }}
                                      required
                                      error={
                                        !!errors.essInputs?.[index]?.nbBattery
                                      }
                                      label="Nbr batteries"
                                      defaultValue={item.nbBattery}
                                      {...register(
                                        `essInputs.${index}.nbBattery` as const,
                                        {required: true, valueAsNumber: true},
                                      )}
                                    />
                                  </FormGroup>
                                  <Box>
                                    Permet de stocker un maximum de{" "}
                                    {getTotalCapacity(
                                      item.batteryTypeId,
                                      item.nbBattery,
                                    )}{" "}
                                    Wh de capacité.
                                  </Box>
                                </Grid>
                              </FormControl>
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip title="Retirer l'ESS">
                            <Button
                              id={`removeEss-${index}`}
                              variant="contained"
                              color="secondary"
                              onClick={() => removeEssList(index)}>
                              <RemoveCircleRoundedIcon />
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    );
                  })}
                </List>
                <Tooltip title="Ajouter un ESS">
                  <Button
                    sx={{width: "64px"}}
                    id={"addEssButton"}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      appendEssList({
                        essTypeId: "",
                        nbBattery: 10,
                        batteryTypeId: "",
                        nbConverter: 1,
                        converterTypeId: "",
                        rescuedReserve: 0,
                      });
                    }}>
                    <AddCircleIcon />
                  </Button>
                </Tooltip>

                <Divider textAlign="left">
                  Modèle
                  <PlaceholderDialog />
                </Divider>
                <div id="section-template">
                  <SelectTemplate
                    labelId="template-id"
                    label={"Template"} // masqué, mais prépare une place quand le label est shrinked, et évite que la ligne passe à travers du label
                    sx={{minWidth: 200}}
                    name={`template`}
                    error={!!errors.template}
                    control={control}
                  />
                  {watch(`template`) === "custom" && (
                    <Upload
                      name="file"
                      headers={
                        sizeaApi.getUploadHeaders() as unknown as HttpRequestHeader
                      }
                      onChange={uploadTemplateOnChange}
                      accept=".docx"
                      action={sizeaApi.getUploadCustomTemplateUrl()}
                      listType="picture-card"
                      showUploadList={true}
                      maxCount={1}
                      style={{fontFamily: "Montserrat"}}
                      defaultFileList={
                        watch("customTemplate")
                          ? [
                              {
                                uid: watch("customTemplate") || "",
                                name: "Fichier modèle indiqué par l'URL",
                              },
                            ]
                          : []
                      }>
                      Déposez votre fichier ici
                    </Upload>
                  )}
                </div>
                <Divider></Divider>
                <Grid container justifyContent={"center"} direction={"row"}>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      type="submit">
                      Valider
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </FormControl>
          </form>
        </Grid>
      </CardContent>
    </Card>
  );
}
