import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchInstallators} from "../../store/form/installators.store";
import {AppDispatch, RootState} from "../../store/store";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function SelectInstallator(props: any) {
  const dispatch = useDispatch<AppDispatch>();
  const installatorsList = useSelector(
    (state: RootState) => state.installators.data,
  );

  const {register, options, name, setValue, ...selectProps} = props;

  useEffect(() => {
    if (installatorsList.length === 0) dispatch(fetchInstallators());
  }, []);

  const onClear = () => {
    setValue(name, null);
  };

  const getMenuItemInstallator = () => {
    const menu: React.JSX.Element[] = [];
    Object.values(installatorsList).forEach((value, index) => {
      menu.push(
        <MenuItem
          data-cy={`cypressSelectInstallatorOption${index}`}
          key={index}
          value={value.id}>
          {value.firstName + " " + value.lastName}
        </MenuItem>,
      );
    });

    return menu;
  };

  return (
    <>
      <InputLabel
        error={props.error}
        id="selectInstallatorLabel"
        required={props.options.required}>
        {props.label}
      </InputLabel>
      <Select
        {...selectProps}
        {...register(name, options)}
        labelId="selectInstallatorLabel"
        data-cy="cypressSelectInstallator"
        id="selectInstallator"
        value={
          props.preloadValue === undefined || props.preloadValue === null
            ? ""
            : props.preloadValue
        }
        color="primary"
        required={props.options.required}
        endAdornment={
          <IconButton
            size="small"
            sx={{
              display: props.preloadValue ? "" : "none",
              marginRight: props.preloadValue ? "20px" : "0px",
            }}
            onClick={onClear}>
            <ClearIcon />
          </IconButton>
        }>
        {getMenuItemInstallator()}
      </Select>
    </>
  );
}
