import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {useEffect, useState, useMemo} from "react";
import apiV2 from "../../service/api/ApiV2";

import {BehaviorSubject} from "rxjs";
import {debounceTime} from "rxjs/operators";

import {ClientSearch} from "../../interfaces/client/ClientSearch";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";

type AutoCompleteClientProps = {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  options: any;
  name: string;
  placeholder: string;
  label: string;
  error: boolean;
  preloadValue?: any;
  handleClientChange: (newClientSearch: ClientSearch | null) => void;
};

export default function AutocompleteClient(props: AutoCompleteClientProps) {
  const {register, setValue, trigger, options, name, ...selectProps} = props;

  const search$ = useMemo(() => new BehaviorSubject(""), []);

  const [inputValue, setInputValue] = useState("");
  const [clientsSearch, setClientsSearch] = useState<ClientSearch[]>([]);
  const [clientSearch, setClientSearch] = useState<ClientSearch | null>(null);

  const onClear = (reason: any) => {
    if (reason === "clear") {
      setValue(name, null);
      trigger(name);
      return;
    }
  };

  const fetchClient = async (id: number) => {
    await apiV2.detailClient(id).then((data) => {
      const currentClientSearch = {} as ClientSearch;
      currentClientSearch.id = data.id ? data.id : 0;
      currentClientSearch.lastName = data.lastName;
      currentClientSearch.firstName = data.firstName;
      currentClientSearch.email = data.email;
      currentClientSearch.platformType = data.platformType;
      setClientSearch(currentClientSearch);
      setValue(name, currentClientSearch.id);
      trigger(name);
    });
  };

  useEffect(() => {
    search$.next(inputValue);
  }, [inputValue, search$]);

  useEffect(() => {
    if (inputValue.length >= 3) {
      const subscription = search$.pipe(debounceTime(400)).subscribe(() => {
        apiV2.searchClients(inputValue).then(setClientsSearch);
      });
      return () => subscription.unsubscribe();
    }
  }, [inputValue, search$]);

  useEffect(() => {
    if (
      props.preloadValue &&
      clientSearch === null &&
      !isNaN(props.preloadValue)
    ) {
      fetchClient(props.preloadValue);
    }
  }, [props.preloadValue]);

  return (
    <div>
      <Autocomplete
        id={"clientAutocomplete"}
        {...register(name, options)}
        filterOptions={(options) => options}
        disablePortal
        options={clientsSearch}
        getOptionLabel={(option: ClientSearch) =>
          option.firstName + " " + option.lastName + " - " + option.email
        }
        isOptionEqualToValue={(option: ClientSearch, value: any) =>
          option.id === value.id
        }
        value={clientSearch}
        noOptionsText="Aucun client trouvé"
        renderInput={(params) => (
          <TextField
            {...params}
            {...selectProps}
            placeholder={props.placeholder}
            label={props.label}
            InputLabelProps={{shrink: true}}
            required={options.required}
            helperText={
              props.error && options.required
                ? "Le client est obligatoire"
                : null
            }
          />
        )}
        onInputChange={(event, newValue) => {
          if (event?.type === "change" && newValue.length >= 3) {
            setInputValue(newValue);
          }
        }}
        onChange={(event, newValue: ClientSearch | null, reason) => {
          setClientSearch(newValue);
          if (event.type === "click") {
            setValue(name, newValue?.id);
            trigger(name);
            props.handleClientChange(newValue);
          }
          onClear(reason);
          if (newValue === null) {
            return;
          }
        }}
      />
    </div>
  );
}
