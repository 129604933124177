import React, {useState, useEffect} from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";

import NewReleasesIcon from "@mui/icons-material/NewReleases";

import apiDirectus from "../../service/api/ApiDirectus";

import {Release} from "../../interfaces/directus/Release";

import {formatDateShort} from "../../service/FormatDateTime";

export default function ReleasesCard() {
  const [releases, setReleases] = useState<Release[] | null>(null);

  useEffect(() => {
    apiDirectus.getFeatures().then((data) => setReleases(data));
  }, []);

  return (
    <Card
      className="custom-card"
      style={{height: "550px"}}
      data-testid="releasesCard">
      <CardContent>
        <Toolbar style={{marginLeft: "5px", marginTop: "-10px", padding: 0}}>
          <NewReleasesIcon
            fontSize="large"
            style={{marginRight: "10px"}}></NewReleasesIcon>
          <Typography variant="h5">Nouveautés</Typography>
        </Toolbar>
        <List style={{marginTop: "-5px", height: "460px", overflow: "overlay"}}>
          {releases === null ? (
            <CircularProgress size={20} />
          ) : (
            releases.map(({published, features}, i) => (
              <ListItem style={{marginBottom: "-15px"}} key={i}>
                <ListItemText
                  style={{marginTop: "-5px"}}
                  disableTypography
                  primary={
                    <Typography
                      style={{fontWeight: "bold", marginBottom: "5px"}}
                      variant="subtitle1">
                      {formatDateShort(published)}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {features.map(({id, title, description, url}) => (
                        <div
                          style={{marginLeft: "15px", marginBottom: "10px"}}
                          key={id}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "-10px",
                            }}
                            variant="subtitle1"
                            color="primary">
                            • {title}
                          </Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: description,
                            }}></div>
                          {url && <a href={url}>En savoir plus</a>}
                        </div>
                      ))}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))
          )}
        </List>
      </CardContent>
    </Card>
  );
}
