import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LaunchIcon from "@mui/icons-material/Launch";

const URL_APP = "https://implantation-trackers.serveur-okwind.fr/implantation";

export default function ImplantationView() {
  const openImplantationApp = (): void => {
    const newWindow = window.open(
      `${URL_APP}`,
      "_blank",
      "noopener,noreferrer",
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div style={{justifyContent: "center", display: "flex", width: "100%"}}>
      <Card
        style={{
          margin: "auto",
          borderRadius: "8px",
          boxShadow: "none",
          minHeight: "150px",
          width: "500px",
        }}
        sx={{display: "flex"}}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <CardContent sx={{flex: "1 0 auto"}}>
            <Typography component="div" variant="h5">
              Outil d'implantation
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div">
              Cette application permet de vérifier l'implantation d'un site en
              fonction de plusieurs critères (altitude, distance à la cote...)
            </Typography>
          </CardContent>
          <Box sx={{display: "flex", alignItems: "flex-center", pl: 1, pb: 1}}>
            <Button
              style={{marginLeft: "auto", marginRight: "10px"}}
              data-testid="openImplantationToolButton"
              variant="outlined"
              onClick={() => openImplantationApp()}
              startIcon={<LaunchIcon />}>
              Ouvrir l'application
            </Button>
          </Box>
        </Box>
      </Card>
    </div>
  );
}
