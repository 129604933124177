import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {User} from "../../interfaces/User";
import apiV2 from "../../service/api/ApiV2";

interface InstallatorsState {
  data: User[];
  loading: boolean;
  error: string | null;
}

const initialState: InstallatorsState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchInstallators = createAsyncThunk(
  "installators/fetchInstallators",
  async () => {
    return apiV2.installators();
  },
);

const installatorsSlice = createSlice({
  name: "installators",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstallators.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInstallators.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchInstallators.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export default installatorsSlice.reducer;
