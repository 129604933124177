import {Plant} from "../../../../interfaces/Plant";
import {Tracker} from "../../../../interfaces/Tracker";
import {Dayjs} from "dayjs";
import {TrackerEventChart} from "./TrackerEventChart";
import {ChartOptions} from "chart.js";

type TrackerEventChartsProps = {
  plant: Plant;
  trackers: Tracker[];
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

export function TrackerEventCharts(props: TrackerEventChartsProps) {
  return (
    <div style={{marginBottom: "20px", marginTop: "10px"}}>
      {props.trackers.map((tracker) => {
        return (
          <TrackerEventChart
            key={tracker.id}
            tracker={tracker}
            startDate={props.startDate}
            endDate={props.endDate}
            plant={props.plant}
            options={props.options}
          />
        );
      })}
    </div>
  );
}
