import {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import {FileSearch} from "../../../interfaces/documentation/FileSearch";

import apiDirectus from "../../../service/api/ApiDirectus";
import DownloadButton from "../../../component/DownloadButton";
import DirectusService from "../../../service/DirectusService";

type SoftwareCardProps = {
  files: FileSearch[];
};

export default function SoftwareCard(props: SoftwareCardProps) {
  const [software, setSoftware] = useState<FileSearch>();
  const [logo, setImg] = useState<Uint8Array>();

  const getLogo = async (id: string) => {
    await apiDirectus.getImage(id).then((response) => {
      response
        .getReader()
        .read()
        .then((buff) => {
          setImg(buff.value);
        });
    });
  };

  useEffect(() => {
    props.files.forEach((element) => {
      if (element.type.includes("image/")) {
        getLogo(element.id);
      } else {
        setSoftware(element);
      }
    });
  }, [props]);

  return (
    <div style={{justifyContent: "center", display: "flex", width: "100%"}}>
      <Card
        style={{
          borderRadius: "8px",
          boxShadow: "none",
          height: "320px",
          padding: "10px",
          overflow: "overlay",
        }}
        sx={{display: "flex"}}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{marginLeft: "10px"}}
              component="div"
              variant="h5">
              {software?.title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            style={{padding: "15px"}}
            sx={{backgroundColor: "transparent"}}>
            {logo && (
              <CardMedia
                component="img"
                sx={{display: {xs: "none", sm: "block"}}}
                image={URL.createObjectURL(
                  new Blob([logo.buffer], {type: "image/png"}),
                )}
                alt="Image accueil configurateur"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={8} md={9} style={{marginTop: "-15px"}}>
            <CardContent>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                component="div">
                <b>Description: </b> {software?.description}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                component="div">
                <b>Version:</b> {software?.Version}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                component="div">
                <b>Taille:</b>{" "}
                {software
                  ? (software?.filesize / 1000000).toFixed(1) + " Mo"
                  : "Inconnu"}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                component="div">
                <b>Type de fichier:</b> (.
                {software?.filename_disk.split(".")[1]})
              </Typography>
            </CardContent>
          </Grid>
          <Grid item xs={12}>
            {software && (
              <div>
                <Typography
                  style={{marginLeft: "10px", marginTop: "-15px"}}
                  variant="subtitle2"
                  color="text.secondary"
                  component="div">
                  <b>Tag </b>
                  {software.tags?.map((tag) => (
                    <Chip key={tag} style={{margin: "5px"}} label={tag}></Chip>
                  ))}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item xs={12}>
            {software && (
              <div>
                <DownloadButton
                  icon={false}
                  url={DirectusService.getUrlDownload(software)}
                  filename={software.filename_download}></DownloadButton>
              </div>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
