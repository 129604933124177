import Plant from "../../interfaces/control/Plant";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import apiGateway from "../../service/api/ApiGateway";
import {PlantFilters} from "../../interfaces/control/PlantFilters";

type PlantsStore = {
  plantDetail: Plant | null;
  data: Plant[];
  loading: boolean;
  error: string | null;
};

const initialState: PlantsStore = {
  plantDetail: null,
  data: [],
  loading: false,
  error: null,
};

export const fetchPlants = createAsyncThunk(
  "plants/fetchPlants",
  async (filters: PlantFilters) => {
    return apiGateway.apiControl.getPlants(filters);
  },
);

export const fetchPlant = createAsyncThunk(
  "plants/fetchPlant",
  async (id: number) => {
    return apiGateway.apiControl.getPlant(id);
  },
);

const plantsToControlSlice = createSlice({
  name: "plants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlants.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlants.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPlants.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchPlant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlant.fulfilled, (state, action) => {
        state.loading = false;
        state.plantDetail = action.payload;
      })
      .addCase(fetchPlant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export default plantsToControlSlice.reducer;
