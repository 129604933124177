import {Plant} from "../../../../interfaces/Plant";
import {ImmersionHeater} from "../../../../interfaces/ImmersionHeater";
import {Dayjs} from "dayjs";
import {ChartOptions} from "chart.js";
import {ImmersionHeaterEventChart} from "./ImmersionHeaterEventChart";

type ImmersionHeaterEventChartsProps = {
  plant: Plant;
  immersionHeaters: ImmersionHeater[];
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

export function ImmersionHeaterEventCharts(
  props: ImmersionHeaterEventChartsProps,
) {
  return (
    <>
      {props.immersionHeaters.map((immersionHeater) => {
        return (
          <ImmersionHeaterEventChart
            key={immersionHeater.id}
            immersionHeater={immersionHeater}
            startDate={props.startDate}
            endDate={props.endDate}
            plant={props.plant}
            options={props.options}
          />
        );
      })}
    </>
  );
}
export {};
