import {styled} from "@mui/material/styles";
import Tooltip, {TooltipProps, tooltipClasses} from "@mui/material/Tooltip";

const InputLabelTooltip = styled(({className, ...props}: TooltipProps) => (
  <span>
    <Tooltip {...props} classes={{popper: className}} />
  </span>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default InputLabelTooltip;
