import CheckBoxFilter from "./CheckBoxFilter";

type optionItem = {id: string | number; label: string};

type onChangeInput = (number | string) | (number | string)[] | null;

type PlantStatusFiltersProps = {
  options: optionItem[];
  colors: string[];
  onChange: (value: onChangeInput, isSelected: boolean) => void;
  value: (string | number)[]; // value is the list of ids of item that are selected
};

export default function PlantStatusFilters(props: PlantStatusFiltersProps) {
  return (
    <>
      {props.options.map((option, index) => {
        return (
          <CheckBoxFilter
            key={option.id}
            onChange={(isSelected) => {
              props.onChange(option.id, isSelected);
            }}
            value={props.value.includes(option.id)}
            style={{color: props.colors[index]}}
            label={option.label}
          />
        );
      })}
    </>
  );
}
