import {useEffect} from "react";
import {FieldValues, UseControllerProps} from "react-hook-form";
import {SelectType} from "./SelectType";
import {SxProps, Theme} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../store/store";
import {fetchBatteryTypes} from "../../../../store/form/batteryTypes.store";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error: boolean | undefined;
  sx?: SxProps<Theme>;
}

export function SelectBatteryType<T extends FieldValues>(props: Props<T>) {
  const dispatch = useDispatch<AppDispatch>();
  const batteryTypes = useSelector((state: RootState) => state.batteryTypes);

  useEffect(() => {
    if (batteryTypes.data.length === 0) dispatch(fetchBatteryTypes());
  }, []);

  return (
    <SelectType
      types={batteryTypes.data}
      sx={props.sx}
      error={props.error}
      control={props.control}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
}
