import React, {useState, useEffect} from "react";
import apiV2 from "../../../../../service/api/ApiV2";

import avatarLumioo from "../../../../../assets/plant/default_photo_lumioo.png";
import avatarOkwind from "../../../../../assets/plant/default_photo_okwind.png";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";

import {DialogProps, Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import CloseIcon from "@mui/icons-material/Close";
import TransitionSlideUp from "../../../../../service/transition/TransitionSlideUp";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

import {Plant} from "../../../../../interfaces/Plant";
import PlantGeneral from "./PlantGeneral";
import PlantSetting from "./PlantSetting";
import PlantActions from "./PlantActions";
import {PlatformTypeEnum} from "../../../../../interfaces/client/PlatformTypeEnum";

type PlantDialogProps = {
  plant: Plant;
  open: boolean;
  handleClose: () => void;
};

export default function PlantDialog(props: PlantDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [maxWidth] = useState<DialogProps["maxWidth"]>("lg");

  const [value, setValue] = useState("1");
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    const loadImage = async () => {
      if (props.plant.image) {
        const src = await apiV2.getPlantImage(props.plant.image.contentUrl);
        setImageSrc(src);
      } else {
        setImageSrc(
          props.plant.platform === PlatformTypeEnum.OKWIND
            ? avatarOkwind
            : avatarLumioo,
        );
      }
    };
    loadImage();
  }, [props.plant.image]);

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    props.handleClose();
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      fullScreen={isMobile}
      open={props.open}
      onClose={handleClose}
      style={{borderRadius: 22}}
      TransitionComponent={TransitionSlideUp}>
      <DialogTitle style={{padding: 0}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            {props.plant.name}
            <span
              style={{
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.common.white
                    : theme.palette.secondaryFont,
              }}>
              {" "}
              {props.plant.serialNumber}
            </span>
          </Typography>
          <IconButton
            color="secondary"
            style={{background: "rgba(53, 186, 7, 0.12)"}}
            aria-label="close dialog"
            edge="start"
            onClick={props.handleClose}>
            <CloseIcon
              style={{
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.common.white
                    : theme.palette.common.black,
              }}
            />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider style={{margin: 0}}></Divider>
      <DialogContent style={{minWidth: !isMobile ? "800px" : "200px"}}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Avatar
              alt="Plant avatar"
              src={imageSrc}
              sx={{width: 175, height: 175, margin: "auto"}}
            />
          </Grid>
          <Grid item xs={12} md={8} style={{height: "350px", padding: "0px"}}>
            <TabContext value={value}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs tracker">
                <Tab label="Informations" value="1" />
                <Tab label="Paramètres" value="2" />
                <Tab label="Actions" value="3" />
              </Tabs>
              <TabPanel value="1" style={{padding: "10px"}}>
                <PlantGeneral plant={props.plant}></PlantGeneral>
              </TabPanel>
              <TabPanel value="2" style={{padding: "10px"}}>
                <PlantSetting plant={props.plant}></PlantSetting>
              </TabPanel>
              <TabPanel value="3" style={{padding: "10px"}}>
                <PlantActions plant={props.plant}></PlantActions>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
        <Button variant="outlined" color="inherit" onClick={props.handleClose}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
