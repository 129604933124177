import {useTheme} from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import SensorsOffIcon from "@mui/icons-material/SensorsOff";

type SynchronisedDivProps = {
  isSynchronised: boolean;
};

export default function SynchronisedDiv(props: SynchronisedDivProps) {
  const theme = useTheme();

  if (!props.isSynchronised) {
    return (
      <div
        style={{
          color: theme.palette.notSynchronised,
          display: "flex",
          alignItems: "center",
        }}>
        <SensorsOffIcon />
        <span style={{paddingLeft: 5}}>Déconnecté</span>
      </div>
    );
  }
  return (
    <div
      style={{
        color: theme.palette.success.main,
        display: "flex",
        alignItems: "center",
      }}>
      <SensorsIcon />
      <span style={{paddingLeft: 5}}>Données synchronisées</span>
    </div>
  );
}
