export interface RougnessRequest {
  z0: number;
  zmin: number;
}

export enum PoleTypeEnum {
  STANDARD = "STANDARD",
  SHORTENED = "SHORTENED",
  EXTENDED = "EXTENDED",
  SHORTENED_EXTENDED = "SHORTENED_EXTENDED",
}

export enum TrackerTypeEnum {
  TRK75 = "TRK75",
  TREA40000 = "TREA40000",
  LUMIOO = "LUMIOO",
}

export interface ImplantationRequest {
  latitude: number;
  longitude: number;
  tracker_type: TrackerTypeEnum;
  pole_type: PoleTypeEnum;
  roughness_lengths: RougnessRequest[];
}

export interface CriteriaValueReponse {
  value: number;
  parameter_name: string;
}
export interface CriteriaReponse {
  status: string;
  values: CriteriaValueReponse[];
}

export enum GeneralTypeEnum {
  OK = "OK",
}

export interface ImplantationResponse {
  general: string;
  wind: CriteriaReponse;
  snow: CriteriaReponse;
  distance_to_coast: CriteriaReponse;
  elevation: CriteriaReponse;
}
