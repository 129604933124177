import CardRow from "../CardRow";
import {Meter} from "../../../../../interfaces/Meter";

interface propsInterface {
  meter: Meter;
}

export default function MeterGeneral(props: propsInterface) {
  return (
    <div>
      <h4>Général</h4>

      <CardRow label="Identifiant">{props.meter.id}</CardRow>

      <CardRow label="Fabricant">{props.meter.type.manufacturer}</CardRow>

      <CardRow label="Référence">{props.meter.type.deviceDescription}</CardRow>

      <CardRow label="Numéro de série">{props.meter.type.serialNumber}</CardRow>
    </div>
  );
}
