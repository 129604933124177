import * as React from "react";
import {DataGrid, GridColDef, GridRowId} from "@mui/x-data-grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import apiV2 from "../../../service/api/ApiV2";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {AlarmType} from "../../../interfaces/AlarmType";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {formatAlarmLevel} from "../../../service/FormatAlarm";

const columns: GridColDef[] = [
  {
    field: "label",
    headerName: "Label",
    description: "Description simple de l'alarme",
    width: 350,
    hideable: false,
    sortable: false,
  },
  {
    field: "reference",
    headerName: "Référence",
    description: "Reference de l'alarme",
    width: 250,
    hideable: false,
    sortable: false,
  },
  {
    field: "level",
    headerName: "Niveau",
    description: "Niveau de l alarme (0-10-20-30)",
    width: 250,
    hideable: false,
    sortable: false,
    renderCell: (params) => formatAlarmLevel(params.value),
    cellClassName: (params) => `alarm-lvl-${params.row.level}`,
  },
];

const Search = styled("div")(({theme}) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.08)"
      : theme.palette.common.white,
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

interface GetSelectedAlarmType {
  (selectedRow: Array<AlarmType>): void;
}

const DataTable = (props: {getListAlarmSelected: GetSelectedAlarmType}) => {
  const [rows, setRow] = React.useState<Array<AlarmType>>([]);
  const [rowsSearch, setRowSearch] = React.useState<Array<AlarmType>>([]);

  const [searched, setSearched] = React.useState<string>("");

  React.useEffect(() => {
    apiV2.trackerAlarmType().then((data) => {
      setRow(data);
      setRowSearch(data);
    });
  }, []);

  const requestSearch = (searchedVal: string) => {
    setRowSearch(rows);
    const filteredRows = rows.filter((row) => {
      return row.label.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setSearched(searchedVal);
    setRowSearch(filteredRows);
  };

  const onRowsSelectionHandler = (ids: Array<GridRowId>) => {
    const selectedRowsData2 = ids.map(
      (id: GridRowId) => rows.find((row: AlarmType) => row.id === id)!,
    );
    props.getListAlarmSelected(selectedRowsData2);
  };

  return (
    <Card style={{width: "100%", borderRadius: "8px", boxShadow: "none"}}>
      <Toolbar>
        <WarningIcon style={{marginBottom: "5px", marginRight: "15px"}} />
        <Typography>
          <b>Selection </b>
        </Typography>
        <Box sx={{flexGrow: 1}} />
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            value={searched}
            onChange={(event) => requestSearch(event.target.value)}
            placeholder="Rechercher..."
            inputProps={{"aria-label": "search"}}
          />
        </Search>
      </Toolbar>
      <CardContent style={{height: 630}}>
        <DataGrid
          style={{width: "100%", boxShadow: "none"}}
          rows={rowsSearch}
          columns={columns}
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          checkboxSelection
          hideFooter
        />
      </CardContent>
    </Card>
  );
};

export default DataTable;
