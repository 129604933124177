import dayjs from "dayjs";
import {SynchronizedChart} from "./SynchronizedChart";
import {useAppSelector} from "../../../../store/hooks";
import {useSearchParams} from "react-router-dom";
import {checkDates} from "../DateService";

export function ChartArea() {
  const plantObjects = useAppSelector((state) => state.plantObjects.value);
  const [searchParams] = useSearchParams();

  // wait for end_date and start_date to be in url
  if (!searchParams.get("start_date") || !searchParams.get("end_date")) {
    return <></>;
  }
  let startDate = dayjs(searchParams.get("start_date"), "YYYY-MM-DD");
  let endDate = dayjs(searchParams.get("end_date"), "YYYY-MM-DD");
  if (!checkDates(startDate, endDate)) {
    return <></>;
  }

  // add one day to end date to get the data of the endDate day
  startDate = startDate.utc();
  endDate = endDate.add(1, "d").utc();

  if (plantObjects === null) {
    // data have not been loaded yet
    return <></>;
  }

  const selectedTrackers = plantObjects.trackers
    .filter((x) => x.isSelected)
    .map((x) => x.tracker);
  const selectedImmersionHeaters = plantObjects.immersionHeaters
    .filter((x) => x.isSelected)
    .map((x) => x.immersionHeater);
  const selectedEss = plantObjects.ess
    .filter((x) => x.isSelected)
    .map((x) => x.ess);
  const selectedMeter = plantObjects.meter?.isSelected
    ? plantObjects.meter.meter
    : null;

  return (
    <SynchronizedChart
      id="synchronizedChart"
      plant={plantObjects.plant}
      trackers={selectedTrackers}
      immersionHeaters={selectedImmersionHeaters}
      ess={selectedEss}
      meter={selectedMeter}
      startDate={startDate}
      endDate={endDate}
    />
  );
}
