import * as React from "react";
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import dayjs, {type Dayjs} from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";

import AppDatepicker from "../../../component/AppDatepicker";
import AlarmsNotViewedBySavFiltersType from "../../../interfaces/alarms/AlarmsNotViewedBySavFiltersType";
import {AppDispatch, RootState} from "../../../store/store";
import {fetchAlarmTypes} from "../../../store/scada/alarms/AlarmTypeStore";
import {GetAlarmType} from "../../../interfaces/alarms/GetAlarmType";
import {
  formatAlarmReference,
  formatAlarmType,
} from "../../../service/FormatAlarm";
import AlarmTypeEnum from "../../../enums/AlarmTypeEnum";
import AutocompletePlantTag from "../../../component/form/AutocompletePlantTag";

import {Autocomplete} from "@mui/material";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Filter from "../plants/filters/Filter";
import InterventionDateFilterOptions from "../InterventionDateFilterOptions";

type AlarmNotViewedBySavFiltersProps = {
  onFilterChange: (filters: AlarmsNotViewedBySavFiltersType) => void;
};

const AlarmsNotViewedBySavFilters = ({
  onFilterChange,
}: AlarmNotViewedBySavFiltersProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch<AppDispatch>();
  const searchParamReferences = searchParams.get("references");
  const searchParamBegin = searchParams.get("begin");
  const searchParamEnd = searchParams.get("end");
  const searchParamInterventionDate = searchParams.get("interventionDate");
  const {data: alarmTypes} = useSelector(
    (state: RootState) => state.alarmTypes,
  );

  const {watch, setValue} = useForm<AlarmsNotViewedBySavFiltersType>({
    defaultValues: {
      references: [],
      begin: searchParamBegin !== null ? searchParamBegin : null,
      end: searchParamEnd !== null ? searchParamEnd : null,
      tags: [],
      interventionDate:
        searchParamInterventionDate !== null
          ? searchParamInterventionDate
          : null,
    },
  });

  const begin = watch("begin");
  const end = watch("end");
  const references = watch("references");
  const tags = watch("tags");
  const interventionDate = watch("interventionDate");

  useEffect(() => {
    if (alarmTypes.length > 0) {
      onFilterChange({
        references: references,
        begin: begin,
        end: end,
        tags: tags,
        interventionDate: interventionDate,
      });
      setSearchParams((oldParams) => {
        if (begin !== "" && begin !== null) {
          oldParams.set("begin", `${begin}`);
        } else {
          oldParams.delete("begin");
        }
        if (end !== "" && end !== null) {
          oldParams.set("end", `${end}`);
        } else {
          oldParams.delete("end");
        }
        if (references && references.length > 0) {
          oldParams.set(
            "references",
            references.map((alarmType) => alarmType.alarmReference).join(","),
          );
        }
        if (interventionDate !== null) {
          oldParams.set("interventionDate", interventionDate);
        }
        return oldParams;
      });
    }
  }, [begin, end, references, tags, interventionDate]);

  useEffect(() => {
    if (alarmTypes.length === 0) {
      dispatch(fetchAlarmTypes([20, 30]));
    }
    const preSelectedReferences =
      searchParamReferences !== null && searchParamReferences !== ""
        ? searchParamReferences.split(",")
        : [];
    setValue(
      "references",
      alarmTypes.filter((alarmType) =>
        preSelectedReferences.includes(alarmType.alarmReference),
      ),
    );
  }, [alarmTypes]);

  return (
    <Grid item>
      <Grid
        container
        direction={{xs: "column", sm: "column", md: "row", lg: "row"}}
        columnSpacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Autocomplete
            size="small"
            limitTags={2}
            multiple
            value={references}
            id="checkboxes-reference"
            options={alarmTypes}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) =>
              option.alarmReference === value.alarmReference
            }
            groupBy={(option: GetAlarmType): AlarmTypeEnum => option.discr}
            onChange={(event, newValue: GetAlarmType[]) => {
              setValue("references", newValue);
              setSearchParams((oldParams) => {
                if (newValue && newValue.length > 0) {
                  oldParams.set(
                    "references",
                    newValue
                      .map((alarmType) => alarmType.alarmReference)
                      .join(","),
                  );
                } else {
                  oldParams.delete("references");
                }
                return oldParams;
              });
            }}
            getOptionLabel={(option) => option.alarmReference}
            renderTags={(value) => {
              const cleanAlarmReference =
                value.length > 0
                  ? formatAlarmReference(value[0].alarmReference)
                  : "";
              return value.length > 0 ? (
                <>
                  <Chip
                    label={cleanAlarmReference}
                    style={{maxWidth: "150px"}}
                    title={cleanAlarmReference}
                  />
                  <span>
                    {value.length > 1 ? ` + ${value.length - 1}` : ""}
                  </span>
                </>
              ) : (
                <></>
              );
            }}
            renderOption={(props, option, {selected}) => {
              const {key, ...optionProps} = props;
              return (
                <ListItem
                  key={key}
                  {...optionProps}
                  style={{paddingTop: 0, paddingBottom: 0}}>
                  <ListItemAvatar>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={selected}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    secondary={option.label}
                    primary={formatAlarmReference(option.alarmReference)}
                  />
                </ListItem>
              );
            }}
            renderGroup={(params) => (
              <div key={params.key}>
                <ListSubheader>{formatAlarmType(params.group)}</ListSubheader>
                <List>{params.children}</List>
              </div>
            )}
            renderInput={(params) => <TextField {...params} label="Alarme" />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <AppDatepicker
            value={[
              begin !== null ? dayjs(begin) : null,
              end !== null ? dayjs(end) : null,
            ]}
            onChange={(dates: [Dayjs | null, Dayjs | null]) => {
              setValue(
                "begin",
                dates[0] !== null ? dates[0].format("YYYY-MM-DD") : null,
              );
              setValue(
                "end",
                dates[1] !== null ? dates[1].format("YYYY-MM-DD") : null,
              );
            }}
            allowEmpty={[true, true]}
            style={{backgroundColor: "inherit"}}
            placeholder={["Survenue entre le", " et le"]}
            showArrows
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <AutocompletePlantTag
            size="small"
            onChange={(newValue) => {
              setValue(
                "tags",
                newValue.map((tag) => tag.label),
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Filter
            options={InterventionDateFilterOptions}
            label="Date d'intervention"
            onChange={(newValue) => {
              setValue("interventionDate", newValue ? newValue.toString() : "");
            }}
            value={interventionDate}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlarmsNotViewedBySavFilters;
