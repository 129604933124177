import {
  REACT_APP_APP_CLIENT_URL,
  REACT_APP_APP_CLIENT_LUMIOO_URL,
} from "../config";

export function getClientAppUrl(isLumioo: boolean, plantId: number) {
  const appClient = isLumioo
    ? REACT_APP_APP_CLIENT_LUMIOO_URL
    : REACT_APP_APP_CLIENT_URL;
  return `${appClient}/home?plant_id=${plantId}&kc_idp_hint=microsoft`;
}
