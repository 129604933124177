import {PvPanelType} from "../../interfaces/tracker/PvPanelType";
import apiV2 from "../../service/api/ApiV2";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

type PvPanelTypeState = {
  data: PvPanelType[];
  loading: boolean;
  error: string | null;
};

const initialState: PvPanelTypeState = {
  data: [],
  loading: false,
  error: null,
};

export const getPvPanelTypes = createAsyncThunk(
  "pvPanelTypes/getPvPanelTypes",
  async () => {
    return await apiV2.getPvPanelTypes();
  },
);

const pvPanelTypesSlice = createSlice({
  name: "pvPanelTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPvPanelTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPvPanelTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getPvPanelTypes.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ||
          "An error occurred while retrieving pv panel types";
      });
  },
});

export default pvPanelTypesSlice.reducer;
