import dayjs from "dayjs";

export function formatDateTime(date: string | null): string {
  if (date === null || date === undefined) {
    return "N/A";
  }

  return dayjs(date).tz(dayjs.tz.guess()).format("DD/MM/YYYY HH:mm");
}

export function formatDate(date: string | null): string {
  if (date === null || date === undefined) {
    return "N/A";
  }
  return dayjs(date).tz(dayjs.tz.guess()).format("DD/MM/YYYY");
}

export function formatDateShort(date: string | null): string {
  if (date === null || date === undefined) {
    return "N/A";
  }

  return dayjs(date).tz(dayjs.tz.guess()).format("D MMMM YYYY");
}

export function nbDaysUntilToday(date: string | null): string {
  if (date === null) {
    return "N/A";
  }

  const date1 = dayjs(date);
  const date2 = dayjs();
  const days = date2.diff(date1, "days");

  return days.toString();
}

export function formatCountdown(date: string | null): string {
  if (date === null) {
    return "N/A";
  }

  const date1 = dayjs(date);
  const today = dayjs();
  if (date1.isSame(today, "day")) {
    return "Aujourd'hui";
  } else {
    const days = today.diff(date1, "days") + 1;
    return `il y a ${days} jour` + (days > 1 ? "s" : "");
  }
}
