import {useEffect, useState} from "react";
import {forkJoin} from "rxjs";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import SynchronisedDiv from "../SynchronisedDiv";
import StatusDiv from "../StatusDiv";
import AppCollapse from "../AppCollapse";
import PlantDialog from "./PlantDialog";
import apiV2 from "../../../../../service/api/ApiV2";
import {PlantStatus} from "../../../../../interfaces/PlantStatus";
import {Plant} from "../../../../../interfaces/Plant";
import {AlarmsList} from "../AlarmsList";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import {Button} from "@mui/material";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import {RootState} from "../../../../../store/store";
import {getClientAppUrl} from "../../../../../service/ClientAppService";

type PlantCardProps = {
  plant: Plant;
};

export default function PlantCard(props: PlantCardProps) {
  const [plantStatus, setPlantStatus] = useState<PlantStatus>();

  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const authorization = useSelector(
    (state: RootState) => state.authorization.data,
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openClientApp = (): void => {
    const isLumioo = props.plant.platform === "lumioo";
    const newWindow = window.open(
      getClientAppUrl(isLumioo, props.plant.id),
      "_blank",
      "noopener,noreferrer",
    );
    if (newWindow) newWindow.opener = null;
  };

  const openInstallationControlPage = (): void => {
    navigate(`/fleet/control?plant_id=${props.plant.id}`);
  };

  useEffect(() => {
    const status = forkJoin({
      plantStatus: apiV2.plantStatus(props.plant.id),
    });

    status.subscribe({
      next: (results) => {
        setPlantStatus(results.plantStatus);
      },
    });
  }, [props.plant]);

  if (!plantStatus) return <></>;
  return (
    <Card className="custom-card">
      <Grid container sx={{pb: 0}}>
        <Grid item xs={12}>
          <CardContent sx={{"&:last-child": {pb: 1}}}>
            <Grid container sx={{pb: 0}}>
              <Grid item xs={11}>
                <Typography component="div" className="scada-card-title">
                  {props.plant.name}
                  <span
                    style={{
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.common.white
                          : theme.palette.secondaryFont,
                    }}>
                    {" "}
                    {props.plant.serialNumber}
                  </span>
                </Typography>
                <SynchronisedDiv isSynchronised={plantStatus.isSynchronised} />
                {plantStatus.isSynchronised && (
                  <StatusDiv statusType={plantStatus.statusType} />
                )}
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  marginTop: "-5px",
                  paddingLeft: "0px",
                }}>
                <Tooltip title="Paramètres">
                  <IconButton
                    aria-label="delete"
                    onClick={handleOpen}
                    data-cy="cypressTrackerCardSettingsButton">
                    <SettingsIcon fontSize="inherit" color="secondary" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{mt: "10px"}} style={{textAlign: "center"}}>
              <Button
                sx={{
                  marginRight: 2,
                  marginLeft: 0,
                  width: 200,
                  "&.MuiButton-root": {
                    color:
                      theme.palette.mode === "dark" ? "white" : "#00000099",
                    borderColor:
                      theme.palette.mode === "dark" ? "white" : "#00000099",
                  },
                }}
                variant="outlined"
                onClick={() => openClientApp()}
                startIcon={<PhonelinkIcon />}>
                Espace client
              </Button>
              {authorization?.authAppInstallationControl && (
                <Button
                  sx={{
                    marginRight: 2,
                    marginLeft: 0,
                    marginTop: 1,
                    width: 200,
                  }}
                  color="primary"
                  variant="outlined"
                  onClick={() => openInstallationControlPage()}
                  startIcon={<ChecklistOutlinedIcon />}>
                  Contrôle
                </Button>
              )}
            </Grid>
          </CardContent>
        </Grid>
        <Grid item xs={12}>
          <CardContent sx={{"&:last-child": {pt: 0, pb: 1}}}>
            <AppCollapse title="Alarmes">
              {plantStatus.alarms.length > 0 ? (
                <AlarmsList alarms={plantStatus.alarms} />
              ) : (
                <p>Aucune alarmes</p>
              )}
            </AppCollapse>
          </CardContent>
        </Grid>
      </Grid>
      <PlantDialog
        plant={props.plant}
        open={open}
        handleClose={handleClose}></PlantDialog>
    </Card>
  );
}
