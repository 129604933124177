import {Box, Stack} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import {Roughness} from "../model/Roughness";

type Props = {
  item: Roughness;
};

export default function RoughnessTab(props: Props) {
  return (
    <TabPanel value={props.item.z0.toString()} style={{padding: "10px"}}>
      <Box
        height={{xs: "calc()", sm: 300}}
        width={{xs: "100%", sm: 900}}
        overflow={"auto"}>
        <Stack
          direction={{xs: "column", sm: "row"}}
          alignItems="left"
          spacing={2}>
          {props.item.images.map((image) => {
            return (
              <img
                alt="tracker"
                style={{
                  maxWidth: 400,
                  maxHeight: 400,
                }}
                src={image}
              />
            );
          })}
        </Stack>
      </Box>
      <Box
        sx={{
          paddingTop: 1,
          textAlign: "center",
        }}>
        {props.item.description}
      </Box>
    </TabPanel>
  );
}
