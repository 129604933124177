import Box from "@mui/material/Box";
import PrivateRoutes from "../../../PrivateRoutes";

function Sizea() {
  return (
    <Box sx={{display: "flex"}}>
      <PrivateRoutes authField="authAppSizea" />
    </Box>
  );
}

export default Sizea;
