import React, {
  ForwardRefRenderFunction,
  RefObject,
  useImperativeHandle,
} from "react";
import Box from "@mui/material/Box";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {NumberPvPanel} from "../../../../../../interfaces/tracker/NumberPvPanel";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../../../store/store";
import TextField from "@mui/material/TextField";
import {updateTrackerNumberPvPanel} from "../../../../../../store/scada/tracker/trackers.store";

type NumberPvPanelFormHandle = {
  submitForm: () => void;
};

type NumberPvPanelFormProps = {
  formRef: RefObject<NumberPvPanelFormHandle>;
  index: number;
  onSuccess: () => void;
};

const NumberPvPanelForm: ForwardRefRenderFunction<
  NumberPvPanelFormHandle,
  NumberPvPanelFormProps
> = (props: NumberPvPanelFormProps) => {
  const trackers = useSelector((state: RootState) => state.trackers);
  const dispatch = useDispatch<AppDispatch>();

  const {formRef, index} = props;

  const {
    control,
    register,
    handleSubmit,
    trigger,
    formState: {errors},
  } = useForm<NumberPvPanel>({
    defaultValues: {
      trackerId: trackers.data[index].id,
      numberPvPanel: trackers.data[index].moduleQuantity,
    },
  });

  const onSubmit: SubmitHandler<NumberPvPanel> = async (data) => {
    data.numberPvPanel = parseInt(`${data.numberPvPanel}`);
    await dispatch(updateTrackerNumberPvPanel(data));
    props.onSuccess();
  };

  // permet d appeler le submit form depuis le composant parent
  useImperativeHandle(formRef, () => ({
    submitForm() {
      trigger();
      handleSubmit(onSubmit)();
    },
  }));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete={"off"}>
        <Box
          component="div"
          style={{marginTop: "0px", marginBottom: "-20px", padding: "0px"}}>
          <Controller
            name="numberPvPanel"
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                id="numberPvPanel-field"
                type="number"
                variant="standard"
                size="small"
                color="primary"
                style={{margin: "0px", padding: "0px", width: "90%"}}
                required
                error={!!errors.numberPvPanel}
                {...register("numberPvPanel", {
                  required: "Le nombre de panneaux est obligatoire",
                  min: {
                    value: 0,
                    message: "Le nombre de panneaux ne peut pas être négatif",
                  },
                  max: {
                    value: 70,
                    message:
                      "Le nombre de panneaux ne peut pas être supérieur à 70",
                  },
                })}
                fullWidth
                helperText={
                  errors.numberPvPanel ? errors.numberPvPanel.message : null
                }
              />
            )}
          />
        </Box>
      </form>
    </>
  );
};

export default React.forwardRef(NumberPvPanelForm);
