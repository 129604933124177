import Chip from "@mui/material/Chip";

const APP_VERSION = "1.19.3";

const AppVersion = () => {
  return (
    <Chip
      style={{marginLeft: "60px", marginBottom: "10px", width: 120}}
      label={APP_VERSION}
    />
  );
};

export default AppVersion;
