import * as React from "react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import LabelIcon from "@mui/icons-material/Label";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import PersonIcon from "@mui/icons-material/Person";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

import {
  formatCountdown,
  formatDateTime,
  nbDaysUntilToday,
} from "../../../../../service/FormatDateTime";
import {PlantSearch} from "../../../../../interfaces/PlantSearch";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../../store/store";
import {fetchPlant} from "../../../../../store/control/plants.store";
import PlantStatusTag from "../../../../../component/PlantStatusTag";
import PlantStatusDescriptionEnum from "../../../../../enums/PlantStatusDescriptionEnum";
import {getClientAppUrl} from "../../../../../service/ClientAppService";
import TagList from "../../../../../component/TagList";

interface propsInterface {
  plantSearch: PlantSearch | null;
}

export default function PlantCard(props: propsInterface) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {plantDetail} = useSelector(
    (state: RootState) => state.plantsToControl,
  );

  const displaySecondaryClient = () => {
    const clientLength = props?.plantSearch
      ? props.plantSearch.clients.length
      : 0;
    if (clientLength <= 1) return <span></span>;
    if (clientLength === 2) return <span>1 client secondaire</span>;
    if (clientLength > 2)
      return <span>{clientLength - 1} clients secondaires</span>;
  };

  const openClientApp = (): void => {
    const isLumioo = props.plantSearch?.platform === "lumioo";
    const newWindow = window.open(
      getClientAppUrl(isLumioo, props.plantSearch!.id),
      "_blank",
      "noopener,noreferrer",
    );
    if (newWindow) newWindow.opener = null;
  };

  const openScadaApp = (): void => {
    const today = new Date().toISOString().split("T")[0];
    navigate(
      "/scada/plant?start_date=" +
        today +
        "&end_date=" +
        today +
        "&plant_id=" +
        props.plantSearch?.id,
    );
  };

  const getPlantSerial = (): string => {
    return props?.plantSearch?.serialNumber
      ? "(" + props?.plantSearch?.serialNumber + ")"
      : "";
  };

  const formatCheckpointCount = () => {
    if (plantDetail !== null) {
      if (plantDetail.total_checkpoint_count === 0) {
        return `N/A`;
      }
      if (plantDetail.ok_checkpoint_count === 0) {
        return `Aucun contrôle sur ${plantDetail.total_checkpoint_count} n'est conforme`;
      }
      if (
        plantDetail.ok_checkpoint_count === plantDetail.total_checkpoint_count
      ) {
        return `Tous les contrôles sont conformes (${plantDetail.total_checkpoint_count})`;
      }
      if (plantDetail.ok_checkpoint_count === 1) {
        return `${plantDetail.ok_checkpoint_count} contrôle sur ${plantDetail.total_checkpoint_count} est conforme`;
      }
      return `${plantDetail.ok_checkpoint_count} contrôles sur ${plantDetail.total_checkpoint_count} sont conformes`;
    }
  };

  useEffect(() => {
    if (props.plantSearch !== null) {
      dispatch(fetchPlant(props.plantSearch.id));
    }
  }, [props.plantSearch]);

  return (
    <Card
      className="custom-card"
      style={{paddingBottom: 10, paddingRight: 5, paddingLeft: 5}}
      data-testid="PlantCard">
      <Grid
        container
        justifyContent="space-between"
        sx={{backgroundColor: "transparent"}}>
        <Grid item xs={12} md={6}>
          {props.plantSearch ? (
            <Grid container>
              <Grid item>
                <List dense>
                  <ListItem className="control-plant-list">
                    <ListItemText
                      data-testid="plantInfos"
                      primary={
                        <React.Fragment>
                          <h2 style={{marginBottom: 0}}>
                            {props?.plantSearch.name}&nbsp;
                            <span
                              style={{
                                color:
                                  theme.palette.mode === "dark"
                                    ? theme.palette.common.white
                                    : theme.palette.secondaryFont,
                              }}>
                              {getPlantSerial()}
                            </span>
                          </h2>
                        </React.Fragment>
                      }
                      secondary={props.plantSearch.address}
                    />
                  </ListItem>
                </List>
                <Grid container>
                  <List dense>
                    <ListItem className="control-plant-list">
                      <ListItemIcon
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText
                        data-testid="clientInfos"
                        primary={
                          props?.plantSearch.clients[0].firstName +
                          " " +
                          props?.plantSearch.clients[0].lastName.toUpperCase() +
                          " - " +
                          props?.plantSearch.clients[0].email
                        }
                        secondary={
                          <React.Fragment>
                            {displaySecondaryClient()}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem className="control-plant-list">
                      <ListItemIcon
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}>
                        <PendingActionsIcon />
                      </ListItemIcon>
                      <ListItemText
                        data-testid="agencyInfos"
                        primary={"Agence: " + props.plantSearch.agency}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{display: "inline"}}
                              component="span"
                              variant="body2"
                              color="text.primary">
                              {"Mise en route: " +
                                formatDateTime(
                                  props?.plantSearch?.operationDate,
                                ) +
                                `(${formatCountdown(props?.plantSearch?.operationDate)})`}
                            </Typography>
                            {parseInt(
                              nbDaysUntilToday(
                                props?.plantSearch?.operationDate,
                              ),
                            ) < 5 ? (
                              <Tooltip title="Attention, la mise en route est récente ce qui peut occasionner un manque de recul pour établir d'un bon fonctionnement">
                                <WarningAmberIcon
                                  color="warning"
                                  style={{
                                    marginBottom: "-5px",
                                    marginLeft: "5px",
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    {plantDetail !== null && (
                      <ListItem className="control-plant-list">
                        <ListItemIcon
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}>
                          <div>
                            <PlantStatusTag status={plantDetail.status} />
                          </div>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            PlantStatusDescriptionEnum[plantDetail.status]
                          }
                          secondary={
                            <Typography color={"text.primary"} variant="body2">
                              {formatCheckpointCount()}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                    <ListItem className="control-plant-list">
                      <ListItemIcon
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}>
                        <LabelIcon />
                      </ListItemIcon>
                      <ListItemText data-testid="clientInfos">
                        {props.plantSearch?.tags !== undefined ? (
                          <TagList
                            tags={props.plantSearch.tags.map((tag) => ({
                              label: tag,
                            }))}
                            justifyChips={"flex-start"}
                            limitTags={5}
                          />
                        ) : (
                          <>-</>
                        )}
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>

        <Grid style={{paddingTop: 20, paddingBottom: 0}} item>
          <Button
            data-testid="openScadaButton"
            color="primary"
            sx={{marginRight: 2, marginBottom: 2, marginLeft: 2}}
            variant="outlined"
            onClick={() => openScadaApp()}
            startIcon={<QueryStatsIcon />}>
            Voir dans scada
          </Button>
          <Button
            data-testid="openClientAppButton"
            sx={{
              marginRight: 2,
              marginBottom: 2,
              marginLeft: 2,
              "&.MuiButton-root": {
                color: theme.palette.mode === "dark" ? "white" : "#00000099",
                borderColor:
                  theme.palette.mode === "dark" ? "white" : "#00000099",
              },
            }}
            variant="outlined"
            onClick={() => openClientApp()}
            startIcon={<PhonelinkIcon />}>
            Espace client
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
