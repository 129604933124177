import {ReactNode} from "react";
import {DataGrid, GridColDef, useGridApiRef} from "@mui/x-data-grid";
import {Link, Tooltip, Dialog, DialogTitle, DialogContent} from "@mui/material";
import {useAppSelector} from "../../../../store/hooks";
import {CurrentAlarmItem} from "../../../../interfaces/CurrentAlarmItem";
import {useAppDispatch} from "../../../../store/hooks";
import {setOpen} from "../../../../store/scada/plants/AlarmSelectedSlice";

type AlarmsTableProps = {
  value: Array<CurrentAlarmItem>;
};

export default function AlarmsTable(props: AlarmsTableProps) {
  const apiRef = useGridApiRef();
  const alarmSelected = useAppSelector((state) => state.alarmSelected.value);
  const open = useAppSelector((state) => state.alarmSelected.open);
  const dispatch = useAppDispatch();

  function plantLink(plantId: number, value: string): ReactNode {
    return (
      <Tooltip title={value}>
        <Link color="secondary" href={`/scada/plant?plant_id=${plantId}`}>
          {value}
        </Link>
      </Tooltip>
    );
  }

  const columns: GridColDef<CurrentAlarmItem>[] = [
    {
      field: "plantName",
      headerName: "Installation",
      width: 210,
      sortable: false,
      renderCell: (params) => plantLink(params.row.plantId, params.value),
    },
  ];

  let rows = props.value.filter((x) => x.alarmTypeReference === alarmSelected);
  // add an id to each row
  rows = rows.map((x, i) => {
    return {...x, id: i};
  });
  // order by plantName
  rows.sort((a, b) => a.plantName.localeCompare(b.plantName));

  return (
    <>
      {alarmSelected && (
        <Dialog
          open={alarmSelected !== null && open}
          sx={{borderRadius: 10}}
          onClose={() => dispatch(setOpen(false))}>
          <DialogTitle>
            <b>{alarmSelected}</b>
          </DialogTitle>
          <DialogContent>
            <DataGrid
              style={{height: "85%", margin: "10px", width: "400px"}}
              rows={rows}
              columns={columns}
              apiRef={apiRef}
              rowHeight={30}
              disableColumnMenu
              columnHeaderHeight={40}
              hideFooterSelectedRowCount
              initialState={{
                pagination: {
                  paginationModel: {pageSize: 13, page: 0},
                },
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
