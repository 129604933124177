import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {CurrentAuthorization} from "../../interfaces/CurrentAuthorization";
import apiV2 from "../../service/api/ApiV2";

interface AuthorizationState {
  data: CurrentAuthorization | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthorizationState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchAuthorizations = createAsyncThunk(
  "auth/fetchAuthorizations",
  async () => {
    return apiV2.currentAuthorization();
  },
);

const authorizationsSlice = createSlice({
  name: "authorizations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthorizations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAuthorizations.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAuthorizations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export default authorizationsSlice.reducer;
