import {GetAlarmType} from "../../../interfaces/alarms/GetAlarmType";
import apiV2 from "../../../service/api/ApiV2";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

type AlarmTypeStore = {
  data: GetAlarmType[];
  loading: boolean;
  error: string | null;
};

const initialState: AlarmTypeStore = {
  data: [],
  loading: false,
  error: null,
};

export const fetchAlarmTypes = createAsyncThunk(
  "alarmTypes/fetchAlarmTypes",
  async (alarmTypeLevels?: number[]) => {
    return apiV2.getAlarmTypes(alarmTypeLevels);
  },
);

const alarmTypeSlice = createSlice({
  name: "alarmType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAlarmTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAlarmTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchAlarmTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Error";
    });
  },
});

export default alarmTypeSlice.reducer;
