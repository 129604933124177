import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ArticleIcon from "@mui/icons-material/Article";

export default function HelpCard() {
  const listHelperUrl = [
    {
      title: "Visite guidée de l'espace partenaire",
      url: "https://okwind.atlassian.net/servicedesk/customer/portal/1/article/74645560",
      subtitle: false,
    },
    {
      title: "Étude Avant-vente",
      url: "https://okwind.atlassian.net/servicedesk/customer/portal/1/article/76775428",
      subtitle: false,
    },
    {
      title: "Implantation",
      url: "https://okwind.atlassian.net/servicedesk/customer/article/76775557",
      subtitle: true,
    },
    {
      title: "Dimensionnement",
      url: "https://okwind.atlassian.net/servicedesk/customer/article/77070399",
      subtitle: true,
    },
    {
      title: "Gestion du parc",
      url: "https://okwind.atlassian.net/servicedesk/customer/portal/1/article/76808210",
      subtitle: false,
    },
    {
      title: "Clients et installations",
      url: "https://okwind.atlassian.net/servicedesk/customer/portal/1/article/74580030",
      subtitle: true,
    },
    {
      title: "Contrôle d'installation",
      url: "https://okwind.atlassian.net/servicedesk/customer/portal/1/article/74580056",
      subtitle: true,
    },
    {
      title: "SCADA",
      url: "https://okwind.atlassian.net/servicedesk/customer/portal/1/article/78446602",
      subtitle: true,
    },
    {
      title: "Ressources",
      url: "https://okwind.atlassian.net/servicedesk/customer/portal/1/article/84180996",
      subtitle: false,
    },
    {
      title: "Gestion des utilisateurs",
      url: "https://okwind.atlassian.net/servicedesk/customer/portal/1/article/84115467",
      subtitle: false,
    },
  ];

  return (
    <Card
      className="custom-card"
      style={{height: "550px", overflow: "overlay"}}
      data-testid="helpCard">
      <CardContent>
        <Toolbar style={{marginLeft: "5px", marginTop: "-10px", padding: 0}}>
          <ArticleIcon
            fontSize="large"
            style={{marginRight: "10px"}}></ArticleIcon>
          <Typography variant="h5">Guide d'utilisation</Typography>
        </Toolbar>
        <List style={{marginTop: "-5px"}}>
          {listHelperUrl.map((element, i) => (
            <ListItem key={i}>
              <ListItemText
                style={{marginTop: "-16px"}}
                disableTypography
                primary={
                  <React.Fragment>
                    <a href={element.url} target="_blank" rel="noreferrer">
                      <Typography
                        style={{
                          fontWeight: "bold",
                          marginLeft: element.subtitle ? "20px" : "0px",
                        }}
                        variant="subtitle1"
                        color="primary">
                        {element.title}
                      </Typography>
                    </a>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>

        <Toolbar style={{marginLeft: "5px", marginTop: "-10px", padding: 0}}>
          <SupportAgentIcon
            fontSize="large"
            style={{marginRight: "10px"}}></SupportAgentIcon>
          <Typography variant="h5">Besoin d'aide ?</Typography>
        </Toolbar>
        <List style={{marginTop: "-5px"}}>
          <ListItem>
            <ListItemText
              style={{marginTop: "-10px"}}
              disableTypography
              primary={
                <Typography variant="subtitle1">
                  S'il vous manque un module ou si vous constatez un bug,
                  contactez votre Key User pour obtenir de l'aide.
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <a
                    href="https://okwind.atlassian.net/servicedesk/customer/portal/1/article/78905367"
                    target="_blank"
                    rel="noreferrer">
                    <Typography
                      style={{fontWeight: "bold"}}
                      variant="subtitle1"
                      color="primary">
                      Identifier mon Key User
                    </Typography>
                  </a>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
