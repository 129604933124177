import {ChartData, ChartDataset, ChartOptions} from "chart.js";
import {Line} from "react-chartjs-2";
import {mergeObjectsRecursive} from "../../../../utils/utils";
import {Plant} from "../../../../interfaces/Plant";
import {Dayjs} from "dayjs";
import {useTheme} from "@mui/material";
import {useEffect, useState, useRef} from "react";
import {formatXLabels, generateDates} from "../DateService";
import apiV2 from "../../../../service/api/ApiV2";
import {forkJoin} from "rxjs";
import {ImmersionHeater} from "../../../../interfaces/ImmersionHeater";
import {CrosshairPlugin} from "../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair";
import {
  borderDashBasedOnDisconnectionAlarms,
  createAlarmTimeserie,
  createYLabels,
  eventLevelColor,
  getCurrentAlarmIndexesFromReference,
  mappingAlarmsToYvalues,
} from "../AlarmService";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";

import GraphResizeService from "../../../../service/GraphResizeService";

type ImmersionHeaterEventChartProps = {
  plant: Plant;
  immersionHeater: ImmersionHeater;
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

export function ImmersionHeaterEventChart(
  props: ImmersionHeaterEventChartProps,
) {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const [chartData, setChartData] = useState<ChartData<"line">>({
    labels: [],
    datasets: [],
  });
  const [mappingAlarmYvalues, setMappingAlarmYvalues] = useState<{
    [key: string]: number;
  }>({});

  const [width, setWidth] = useState(0);

  useEffect(() => {
    GraphResizeService.checkResizeWidth(ref, width, setWidth);
  }, [ref, width, setWidth]);

  useEffect(() => {
    const expectedDates = generateDates(props.startDate, props.endDate);

    async function updateDatasets(
      datasets: ChartDataset<"line">[],
    ): Promise<void> {
      let immersionHeaterAlarms = await apiV2.immersionHeaterAlarms(
        props.startDate,
        props.endDate,
        props.immersionHeater.id,
      );
      immersionHeaterAlarms = immersionHeaterAlarms.map((alarm) => {
        return {
          ...alarm,
          reference: alarm.reference.replace("IMMERSION_HEATER_", " "),
        };
      });

      const alarmYValues = mappingAlarmsToYvalues(immersionHeaterAlarms);
      setMappingAlarmYvalues(alarmYValues);
      for (let i = 0; i < immersionHeaterAlarms.length; i++) {
        datasets.push({
          label: `${immersionHeaterAlarms[i].reference}`,
          data: createAlarmTimeserie(
            expectedDates,
            immersionHeaterAlarms[i],
            alarmYValues[immersionHeaterAlarms[i].reference],
          ),
          borderColor: eventLevelColor(immersionHeaterAlarms[i].level, theme),
          backgroundColor: eventLevelColor(
            immersionHeaterAlarms[i].level,
            theme,
          ),
          borderWidth: 10,
          segment: {
            borderDash: (ctx) => {
              const disconnectionAlarmIndexes =
                getCurrentAlarmIndexesFromReference(
                  immersionHeaterAlarms,
                  "DISCONNECT",
                );
              return borderDashBasedOnDisconnectionAlarms(
                ctx,
                expectedDates,
                immersionHeaterAlarms,
                disconnectionAlarmIndexes,
                i,
              );
            },
          },
        });
      }
    }
    const datasets: ChartDataset<"line">[] = [];
    const waitForDatasets = forkJoin({
      datasets: updateDatasets(datasets),
    });
    waitForDatasets.subscribe({
      next: () => {
        setChartData({
          labels: formatXLabels(expectedDates, props.plant),
          datasets: datasets,
        });
      },
      error: () => {
        setChartData({
          labels: formatXLabels(expectedDates, props.plant),
          datasets: [],
        });
      },
    });
  }, [props, theme]);

  const defaultOptions: ChartOptions<"line"> = {
    plugins: {
      title: {
        display: true,
        text: `Ev TP`,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (tooltipItem) {
            if (chartData.datasets.length === 0) return "";
            return chartData.datasets[tooltipItem.datasetIndex].label;
          },
        },
      },
      annotation: {
        annotations: createYLabels(
          mappingAlarmYvalues,
          props.startDate.tz(props.plant.timezone),
          theme,
        ),
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
          autoSkip: false,
          callback: () => {
            return "";
          },
        },
        min: Math.min(...Object.values(mappingAlarmYvalues)) - 1, // min is set to display all labels
        max: Math.max(...Object.values(mappingAlarmYvalues)) + 1, // max is set to display all labels
      },
    },
  };

  const chartHeight = 90 + 20 * Object.keys(mappingAlarmYvalues).length;
  const options = mergeObjectsRecursive(defaultOptions, props.options);

  return (
    <div ref={ref} className="scada-card-zoom">
      <ResizableBox
        transformScale={0.75}
        className="box"
        width={width}
        height={chartHeight}
        resizeHandles={["s"]}
        minConstraints={[100, chartHeight]}
        maxConstraints={[width, chartHeight * 4]}>
        {/* crosshair plugin is registered locally otherwise it can cause bug on non Line Chart*/}
        <Line
          options={options}
          data={chartData}
          plugins={[CrosshairPlugin]}
          style={{marginLeft: "15px"}}
        />
      </ResizableBox>
    </div>
  );
}
