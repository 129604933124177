import {useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {Placeholder} from "../model";
import HelpIcon from "@mui/icons-material/Help";
import sizeaApi from "../../../../service/api/ApiSizea";
import CloseIcon from "@mui/icons-material/Close";

export function PlaceholderDialog() {
  const theme = useTheme();
  const [placeholders, setPlaceholders] = useState<Placeholder[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    sizeaApi.placeholders().then((data: Placeholder[]) => {
      setPlaceholders(data);
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Les balises du modèle">
        <Button
          sx={{width: "64px"}}
          color="secondary"
          onClick={handleClickOpen}>
          <HelpIcon />
        </Button>
      </Tooltip>
      <Dialog onClose={handleClose} open={open} maxWidth={false}>
        <DialogTitle style={{padding: 0}}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
              Les balises diponibles pour le modèle:
            </Typography>
            <IconButton
              color="secondary"
              style={{background: "rgba(53, 186, 7, 0.12)"}}
              aria-label="close dialog"
              edge="start"
              onClick={handleClose}>
              <CloseIcon
                style={{
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.common.white
                      : theme.palette.common.black,
                }}
              />
            </IconButton>
          </Toolbar>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Balise</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {placeholders.map((placeholder) => (
                  <TableRow
                    key={placeholder.label}
                    sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                    <TableCell component="th" scope="row">
                      {placeholder.label}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {placeholder.description}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions style={{paddingRight: "24px", paddingBottom: "24px"}}>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
