import React, {
  ForwardRefRenderFunction,
  RefObject,
  useImperativeHandle,
} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../../../store/store";
import {SubmitHandler, useForm} from "react-hook-form";
import {updateTrackerPvPanel} from "../../../../../../store/scada/tracker/trackers.store";
import {SetPvPanel} from "../../../../../../interfaces/tracker/SetPvPanel";
import SelectPvPanelType from "../../../../../../component/form/SelectPvPanelType";
import {PvPanelType} from "../../../../../../interfaces/tracker/PvPanelType";

type PvPanelTypeFormHandle = {
  submitForm: () => void;
};

type PvPanelTypeFormProps = {
  formRef: RefObject<PvPanelTypeFormHandle>;
  index: number;
  onSuccess: () => void;
};

const PvPanelTypeForm: ForwardRefRenderFunction<
  PvPanelTypeFormHandle,
  PvPanelTypeFormProps
> = (props: PvPanelTypeFormProps) => {
  const {formRef, index} = props;
  const trackers = useSelector((state: RootState) => state.trackers);
  const currentTracker = trackers.data[index];
  const dispatch = useDispatch<AppDispatch>();
  const pvPanelTypesList = useSelector(
    (state: RootState) => state.pvPanelTypes.data,
  );
  const currentPvPanel = pvPanelTypesList.find(
    (pvPanelType: PvPanelType) =>
      pvPanelType.deviceDescription === currentTracker.pvPanel,
  );

  const {control, register, handleSubmit, trigger} = useForm<SetPvPanel>({
    defaultValues: {
      tracker: trackers.data[index].id,
      pvPanelTypeId: currentPvPanel?.id,
    },
  });

  const onSubmit: SubmitHandler<SetPvPanel> = async (data) => {
    data.pvPanelTypeId = parseInt(`${data.pvPanelTypeId}`);
    const newPvPanelTypeDeviceDescription = pvPanelTypesList.find(
      (pvPanelType: PvPanelType) => pvPanelType.id === data.pvPanelTypeId,
    )?.deviceDescription;
    if (newPvPanelTypeDeviceDescription) {
      await dispatch(
        updateTrackerPvPanel({
          setPvPanel: data,
          deviceDescription: newPvPanelTypeDeviceDescription,
        }),
      );
      props.onSuccess();
    }
  };

  // permet d appeler le submit form depuis le composant parent
  useImperativeHandle(formRef, () => ({
    submitForm() {
      trigger();
      handleSubmit(onSubmit)();
    },
  }));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete={"off"}>
        <div>
          <SelectPvPanelType
            name={"pvPanelTypeId"}
            label={"Type de panneaux"}
            register={register}
            required={true}
            control={control}
          />
        </div>
      </form>
    </>
  );
};

export default React.forwardRef(PvPanelTypeForm);
