import * as React from "react";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {FR, GB} from "country-flag-icons/react/3x2";
import {FR as FRlittle, GB as GBlittle} from "country-flag-icons/react/1x1";
import {ListItemAvatar, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {DataGrid, GridColDef} from "@mui/x-data-grid";

import SearchTextField from "../../../component/form/SearchTextField";
import apiDirectus from "../../../service/api/ApiDirectus";
import {FileSearch} from "../../../interfaces/documentation/FileSearch";
import DirectusService from "../../../service/DirectusService";
import DownloadButton from "../../../component/DownloadButton";
import DocumentTypeView from "./DocumentTypeView";
import DateView from "./DateView";

import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

interface DirectusFieldOption {
  text: string;
  value: string;
}

interface DocumentationFilters {
  searchDocumentation: string;
  selectedModel: DirectusFieldOption[];
  selectedLanguage: DirectusFieldOption[];
  selectedDocumentType: DirectusFieldOption[];
}

export default function ListsFileSearchView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<Array<FileSearch>>([]);
  const [documentTypes, setDocumentTypes] = useState<
    Array<DirectusFieldOption>
  >([]);
  const [models, setModels] = useState<Array<DirectusFieldOption>>([]);
  const [languages, setLanguages] = useState<Array<DirectusFieldOption>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);

  const params = new URLSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {watch, setValue} = useForm<DocumentationFilters>({
    defaultValues: {
      selectedModel: [],
      selectedLanguage: [],
      selectedDocumentType: [],
      searchDocumentation: searchParams.get("search") || "",
    },
  });

  const selectedModel = watch("selectedModel");
  const selectedLanguage = watch("selectedLanguage");
  const searchDocumentation = watch("searchDocumentation");
  const selectedDocumentType = watch("selectedDocumentType");

  const getFilesFromFilter = (
    searchDocumentationParams: string,
    selectedModelParams: DirectusFieldOption[],
    selectedDocumentTypeParams: DirectusFieldOption[],
    selectedLanguageParams: DirectusFieldOption[],
  ) => {
    const currentSearchDocumentation =
      searchDocumentationParams === "" ? null : searchDocumentationParams;
    const currentModels =
      selectedModelParams.length === 0
        ? null
        : selectedModelParams.map((a: DirectusFieldOption) => a.value);
    const currentLanguages =
      selectedLanguageParams.length === 0
        ? null
        : selectedLanguageParams.map((a: DirectusFieldOption) => a.value);
    const currentDocumentTypes =
      selectedDocumentTypeParams.length === 0
        ? null
        : selectedDocumentTypeParams.map((a: DirectusFieldOption) => a.value);

    apiDirectus
      .getFiles(
        currentSearchDocumentation,
        currentDocumentTypes,
        currentModels,
        currentLanguages,
      )
      .then((data: FileSearch[]) => {
        setLoading(false);
        setRows(data);
      });
  };

  const loadData = async (
    typeField: string,
    callback: React.Dispatch<React.SetStateAction<DirectusFieldOption[]>>,
  ) => {
    apiDirectus.getCustomField(typeField).then((response) => {
      callback(
        response.meta.options.choices.sort((choice1, choice2) => {
          return choice1.text.localeCompare(choice2.text);
        }),
      );
    });
  };

  const getPreSelectedData = (
    datas: DirectusFieldOption[],
    urlParamaters: string[],
  ): Array<DirectusFieldOption> => {
    return datas.filter((data: DirectusFieldOption) =>
      urlParamaters.includes(data.value),
    );
  };

  useEffect(() => {
    loadData("Type_de_document", setDocumentTypes);
    loadData("Modele", setModels);
    loadData("Langage", setLanguages);
  }, []);

  useEffect(() => {
    const paramSearchDocumentation = searchParams.get("search") || "";
    setValue(
      "selectedModel",
      getPreSelectedData(models, searchParams.getAll("models")),
    );
    setValue(
      "selectedLanguage",
      getPreSelectedData(languages, searchParams.getAll("languages")),
    );
    setValue(
      "selectedDocumentType",
      getPreSelectedData(documentTypes, searchParams.getAll("types")),
    );
    setValue("searchDocumentation", paramSearchDocumentation);
  }, [models, languages, documentTypes]);

  useEffect(() => {
    if (models.length > 0 && documentTypes.length > 0 && languages.length > 0) {
      setSearchParams(() => {
        if (selectedLanguage.length !== 0) {
          selectedLanguage.forEach((language) =>
            params.append("languages", language.value),
          );
        }
        if (selectedModel.length !== 0) {
          selectedModel.forEach((model) =>
            params.append("models", model.value),
          );
        }
        if (selectedDocumentType.length !== 0) {
          selectedDocumentType.forEach((type) =>
            params.append("types", type.value),
          );
        }
        if (searchDocumentation != "") {
          params.append("search", searchDocumentation);
        }
        return params;
      });

      getFilesFromFilter(
        searchDocumentation,
        selectedModel,
        selectedDocumentType,
        selectedLanguage,
      );
    }
  }, [
    searchDocumentation,
    selectedModel,
    selectedDocumentType,
    selectedLanguage,
  ]);

  const gridColumns: GridColDef<FileSearch>[] = [
    {
      field: "title",
      headerName: "Titre",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <List>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.primary">
                  {params.value}
                </Typography>
              }
            />
          </ListItem>
        </List>
      ),
    },
    {
      field: "Type_de_document",
      headerName: "Type de document",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <DocumentTypeView
          documentTypes={documentTypes}
          typeDeDocument={params.value}
        />
      ),
    },
    {
      field: "Langage",
      headerName: "Langue",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{display: "flex", justifyContent: "center"}}>
          {params.value === "fr" && (
            <FR title="Français" style={{borderRadius: "8px", width: "40px"}} />
          )}
          {params.value === "en" && (
            <GB title="English" style={{borderRadius: "8px", width: "40px"}} />
          )}
        </Box>
      ),
    },
    {
      field: "Version",
      headerName: "Version",
      maxWidth: 100,
      flex: 1,
    },
    {
      field: "Publication",
      headerName: "Date",
      maxWidth: 150,
      flex: 1,
      renderCell: (params) => <DateView date={params.value} />,
    },
    {
      field: "filesize",
      headerName: "Poids",
      maxWidth: 100,
      flex: 1,
      sortable: false,
      valueFormatter: (params) => `${(params.value / 1000000).toFixed(1)} Mo`,
    },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <DownloadButton
              icon={true}
              url={DirectusService.getUrlDownload(params.row)}
              filename={params.row.filename_download}
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  return (
    <Grid container sx={{backgroundColor: "transparent"}}>
      <Card className="search-card">
        <Grid container sx={{backgroundColor: "transparent"}}>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            style={{paddingRight: isMobile ? "0px" : "20px"}}>
            <SearchTextField
              value={searchDocumentation}
              width={"100%"}
              notifyOnClear={true}
              placeholder="Par titre du document"
              onChange={(newValue) => {
                setLoading(true);
                setValue("searchDocumentation", newValue);
              }}
              helper="La recherche doit contenir au moins 3 caractères."
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            style={{paddingRight: isMobile ? "0px" : "20px"}}>
            <FormControl
              fullWidth
              style={{marginTop: isMobile ? "0px" : "15px"}}>
              <Autocomplete
                limitTags={1}
                multiple
                id="checkboxes-type-doc"
                options={documentTypes}
                value={selectedDocumentType}
                disableCloseOnSelect
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(_event: any, newValue: DirectusFieldOption[]) => {
                  setValue("selectedDocumentType", newValue);
                }}
                getOptionLabel={(option) => option.text}
                renderTags={(value, getTagProps) => {
                  const numTags = value.length;
                  const limitTags = 1;
                  return (
                    <Box>
                      {value.slice(0, limitTags).map((option, index) => (
                        <Chip
                          style={{maxWidth: "100%"}}
                          {...getTagProps({index})}
                          key={index}
                          label={option.text}
                        />
                      ))}
                      {numTags > limitTags && ` +${numTags - limitTags}`}
                    </Box>
                  );
                }}
                renderOption={(props, option, {selected}) => {
                  const {key, ...optionProps} = props;
                  return (
                    <ListItem
                      key={key}
                      {...optionProps}
                      style={{paddingTop: 0, paddingBottom: 0}}>
                      <ListItemAvatar>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={selected}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={option.text} />
                    </ListItem>
                  );
                }}
                style={{width: "100%", flexWrap: "nowrap"}}
                renderInput={(params) => (
                  <TextField {...params} label="Type de document" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            style={{paddingRight: isMobile ? "0px" : "20px"}}>
            <FormControl
              fullWidth
              style={{marginTop: isMobile ? "0px" : "15px"}}>
              <Autocomplete
                limitTags={1}
                multiple
                id="checkboxes-model"
                options={models}
                value={selectedModel}
                disableCloseOnSelect
                onChange={(_event: any, newValue: DirectusFieldOption[]) => {
                  setValue("selectedModel", newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) => option.text}
                renderTags={(value, getTagProps) => {
                  const numTags = value.length;
                  const limitTags = 1;
                  return (
                    <Box>
                      {value.slice(0, limitTags).map((option, index) => (
                        <Chip
                          style={{maxWidth: "200px"}}
                          {...getTagProps({index})}
                          key={index}
                          label={option.text}
                        />
                      ))}
                      {numTags > limitTags && ` +${numTags - limitTags}`}
                    </Box>
                  );
                }}
                renderOption={(props, option, {selected}) => {
                  const {key, ...optionProps} = props;
                  return (
                    <ListItem
                      key={key}
                      {...optionProps}
                      style={{paddingTop: 0, paddingBottom: 0}}>
                      <ListItemAvatar>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={selected}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={option.text} />
                    </ListItem>
                  );
                }}
                style={{width: "100%", flexWrap: "nowrap"}}
                renderInput={(params) => (
                  <TextField {...params} label="Modèle" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={2}
            style={{paddingRight: isMobile ? "0px" : "20px"}}>
            <FormControl
              fullWidth
              style={{marginTop: isMobile ? "0px" : "15px"}}>
              <Autocomplete
                limitTags={2}
                multiple
                id="checkboxes-languages"
                options={languages}
                value={selectedLanguage}
                disableCloseOnSelect
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(_event: any, newValue: DirectusFieldOption[]) => {
                  setValue("selectedLanguage", newValue);
                }}
                getOptionLabel={(option) => option.text}
                renderTags={(value, getTagProps) => {
                  const numTags = value.length;
                  const limitTags = 1;
                  return (
                    <Box>
                      {value.slice(0, limitTags).map((option, index) => (
                        <Chip
                          style={{
                            maxWidth:
                              numTags > limitTags - 1 ? "100px" : "100%",
                          }}
                          {...getTagProps({index})}
                          key={index}
                          label={option.text}
                        />
                      ))}
                      {numTags > limitTags && ` +${numTags - limitTags}`}
                    </Box>
                  );
                }}
                renderOption={(props, option, {selected}) => {
                  const {key, ...optionProps} = props;
                  return (
                    <ListItem
                      key={key}
                      {...optionProps}
                      style={{paddingTop: 0, paddingBottom: 0}}>
                      <ListItemAvatar>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={selected}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            {option.value === "fr" && (
                              <FRlittle
                                title="Français"
                                style={{
                                  marginTop: "5px",
                                  marginRight: "10px",
                                  borderRadius: "50px",
                                  width: "18px",
                                }}
                              />
                            )}
                            {option.value === "en" && (
                              <GBlittle
                                title="English"
                                style={{
                                  marginTop: "5px",
                                  marginRight: "10px",
                                  borderRadius: "50px",
                                  width: "18px",
                                }}
                              />
                            )}
                            {option.text}
                          </>
                        }
                      />
                    </ListItem>
                  );
                }}
                style={{width: "100%", flexWrap: "nowrap"}}
                renderInput={(params) => (
                  <TextField {...params} label="Langue" />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Card>

      <Card className="custom-card">
        <Grid container sx={{backgroundColor: "transparent", pl: 0, pr: 0}}>
          <Grid item xs={12}>
            <Collapse in={open}>
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                Avertissement : Certains documents sont à usage interne
                uniquement. Il ne peuvent être communiqués ou reproduits sans
                accord préalable de Groupe OKWIND.
              </Alert>
            </Collapse>
          </Grid>

          <Grid item xs={12} sx={{p: 2}}>
            <Paper sx={{width: "100%", overflow: "hidden", boxShadow: "none"}}>
              <div style={{width: "100%", maxWidth: "100%"}}>
                {rows.length === 0 ? (
                  <Alert severity="info">
                    Désolé, aucune donnée ne correspond à votre recherche.
                    Essayez d'ajuster vos critères de recherche.
                  </Alert>
                ) : (
                  <DataGrid
                    rows={rows}
                    sx={{
                      "&, [class^=MuiDataGrid]": {
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                      },
                    }}
                    columns={gridColumns}
                    initialState={{
                      pagination: {
                        paginationModel: {pageSize: 30, page: 0},
                      },
                    }}
                    localeText={{
                      MuiTablePagination: {
                        labelDisplayedRows: ({from, to, count}) =>
                          `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`,
                        labelRowsPerPage: "Documentations par page :",
                      },
                    }}
                    pageSizeOptions={[30, 50, 100]}
                    loading={!loading && rows.length === 0}
                    getRowId={(row) => row.id}
                    disableRowSelectionOnClick={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                  />
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
