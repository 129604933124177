import {ChartData, ChartDataset, ChartOptions} from "chart.js";
import {Line} from "react-chartjs-2";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";
import {mergeObjectsRecursive} from "../../../../utils/utils";
import {Plant} from "../../../../interfaces/Plant";
import {Tracker} from "../../../../interfaces/Tracker";
import dayjs, {Dayjs} from "dayjs";
import {alpha, useTheme} from "@mui/material";
import {useEffect, useState, useRef} from "react";
import {formatXLabels, generateDates} from "../DateService";
import apiV2 from "../../../../service/api/ApiV2";
import {forkJoin} from "rxjs";
import {CrosshairPlugin} from "../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair";
import {
  borderDashBasedOnDisconnectionAlarms,
  createAlarmTimeserie,
  createYLabels,
  eventLevelColor,
  getCurrentAlarmIndexesFromReference,
  mappingAlarmsToYvalues,
} from "../AlarmService";
import {Grid, Typography} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {fillMissingDate, removeUnnecessaryDate} from "../ParseRawData";

import GraphResizeService from "../../../../service/GraphResizeService";

type TrackerEventChartProps = {
  plant: Plant;
  tracker: Tracker;
  startDate: Dayjs;
  endDate: Dayjs;
  options: ChartOptions<"line">;
};

interface LegendTrackerAlarm {
  id: number;
  label: string;
  reference: string;
  color: string;
}

export function TrackerEventChart(props: TrackerEventChartProps) {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [width, setWidth] = useState(0);
  const [chartData, setChartData] = useState<ChartData<"line">>({
    labels: [],
    datasets: [],
  });
  const [mappingAlarmYvalues, setMappingAlarmYvalues] = useState<{
    [key: string]: number;
  }>({});
  const [legendTrackerAlarms, setLegendTrackerAlarms] = useState<
    Array<LegendTrackerAlarm>
  >([]);

  useEffect(() => {
    GraphResizeService.checkResizeWidth(ref, width, setWidth);
  }, [ref, width, setWidth]);

  useEffect(() => {
    const expectedDates = generateDates(props.startDate, props.endDate);

    async function updateDatasets(
      datasets: ChartDataset<"line">[],
    ): Promise<void> {
      let trackerAlarms = await apiV2.trackerAlarms(
        props.startDate,
        props.endDate,
        props.tracker.id,
      );
      // remove TRACKER_FLAT_TEMPORARY because it is useless to have it on the chart
      trackerAlarms = trackerAlarms.filter(
        (alarm) => alarm.reference !== "TRACKER_FLAT_TEMPORARY",
      );
      trackerAlarms = trackerAlarms.map((alarm) => {
        return {
          ...alarm,
          reference: alarm.reference.replace("TRACKER_", " "),
        };
      });
      const legendTracker = trackerAlarms.map((alarm, index) => {
        const legend = {} as LegendTrackerAlarm;
        legend.id = index;
        legend.reference = alarm.reference;
        legend.label = alarm.label;
        legend.color = eventLevelColor(alarm.level, theme);
        return legend;
      });

      const alarmYValues = mappingAlarmsToYvalues(trackerAlarms);
      alarmYValues["INTERNET"] = 0; // the internet status is not an alarm but we display it on the chart
      setMappingAlarmYvalues(alarmYValues);
      setLegendTrackerAlarms(
        legendTracker.filter(
          (trackerAlarm1, i, arr) =>
            arr.findIndex(
              (trackerAlarm2) => trackerAlarm2.label === trackerAlarm1.label,
            ) === i,
        ),
      );
      for (let i = 0; i < trackerAlarms.length; i++) {
        datasets.push({
          label: `${trackerAlarms[i].reference}`,
          data: createAlarmTimeserie(
            expectedDates,
            trackerAlarms[i],
            alarmYValues[trackerAlarms[i].reference],
          ),
          borderColor: eventLevelColor(trackerAlarms[i].level, theme),
          backgroundColor: eventLevelColor(trackerAlarms[i].level, theme),
          borderWidth: 10,
          segment: {
            borderDash: (ctx) => {
              const disconnectionAlarmIndexes =
                getCurrentAlarmIndexesFromReference(
                  trackerAlarms,
                  "DISCONNECT",
                );
              return borderDashBasedOnDisconnectionAlarms(
                ctx,
                expectedDates,
                trackerAlarms,
                disconnectionAlarmIndexes,
                i,
              );
            },
          },
        });
      }

      let trackerInternetStatus = await apiV2.trackerInternetStatus(
        props.startDate,
        props.endDate,
        props.tracker.id,
      );
      trackerInternetStatus = removeUnnecessaryDate(trackerInternetStatus);
      trackerInternetStatus = fillMissingDate(
        trackerInternetStatus,
        expectedDates,
      );
      datasets.push({
        label: `INTERNET`,
        data: trackerInternetStatus
          .filter((x) => x.internet !== null && x.internet !== undefined)
          .map((x) => {
            if (x.internet)
              return {
                x: dayjs(x.date).valueOf(),
                y: 0.1, // where internet=true will display on the yaxis
              };
            return {
              x: dayjs(x.date).valueOf(),
              y: -0.1, // where internet=false will display on the yaxis
            };
          }),
        showLine: false,
        pointRadius: 2,
        pointBackgroundColor: function (context) {
          const index = context.dataIndex;
          const value = trackerInternetStatus[index]?.internet;
          return value
            ? alpha(theme.palette.success.main, 0.5)
            : alpha(theme.palette.notSynchronised, 0.5);
        },
      });
    }

    const datasets: ChartDataset<"line">[] = [];
    const waitForDatasets = forkJoin({
      datasets: updateDatasets(datasets),
    });
    waitForDatasets.subscribe({
      next: () => {
        setChartData({
          labels: formatXLabels(expectedDates, props.plant),
          datasets: datasets,
        });
      },
      error: () => {
        setChartData({
          labels: formatXLabels(expectedDates, props.plant),
          datasets: [],
        });
      },
    });
  }, [props, theme]);

  const defaultOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: `Ev T${props.tracker.nTrk}`,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (tooltipItem) {
            if (chartData.datasets.length === 0) return "";
            return chartData.datasets[tooltipItem.datasetIndex].label;
          },
        },
      },
      annotation: {
        annotations: createYLabels(
          mappingAlarmYvalues,
          props.startDate.tz(props.plant.timezone),
          theme,
        ),
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
          autoSkip: false,
          callback: () => {
            return "";
          },
        },
        min: Math.min(...Object.values(mappingAlarmYvalues)) - 1, // min is set to display all labels
        max: Math.max(...Object.values(mappingAlarmYvalues)) + 1, // max is set to display all labels
      },
    },
  };

  const chartHeight = 90 + 20 * Object.keys(mappingAlarmYvalues).length;
  const options = mergeObjectsRecursive(defaultOptions, props.options);

  return (
    <div ref={ref} style={{width: "100%"}} className="scada-card-zoom">
      <ResizableBox
        transformScale={0.75}
        className="box"
        width={width}
        height={chartHeight}
        resizeHandles={["s"]}
        minConstraints={[100, chartHeight]}
        maxConstraints={[width, chartHeight * 4]}>
        {/* crosshair plugin is registered locally otherwise it can cause bug on non Line Chart*/}
        <Line
          options={options}
          data={chartData}
          plugins={[CrosshairPlugin]}
          style={{marginLeft: "15px"}}
        />
      </ResizableBox>
      <div
        style={{
          marginLeft: isMobile ? "20px" : "80px",
          marginBottom: "5px",
          marginRight: "25px",
        }}>
        {legendTrackerAlarms.length > 0 && (
          <Grid container style={{marginTop: "5px", marginBottom: "5px"}}>
            {legendTrackerAlarms.map((element, index) => (
              <Grid
                item
                sm={12}
                md={6}
                sx={{paddingTop: 0, paddingBottom: 0}}
                key={index}>
                <Typography variant="subtitle2">
                  <span
                    style={{
                      height: "10px",
                      width: "10px",
                      backgroundColor: element.color,
                      borderRadius: "50%",
                      display: "inline-block",
                    }}></span>
                  <b style={{fontSize: "12px"}}>{element.reference}</b>:{" "}
                  {element.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
}
