import {MapOfPlantStatusesItem} from "../../../../interfaces/MapOfPlantStatusesItem";
import {ChartData} from "chart.js";
import {Pie} from "react-chartjs-2";
import {useEffect, useState} from "react";
import {Card, Theme, useMediaQuery, useTheme} from "@mui/material";
import {ChartOptions} from "chart.js";
import {eventLevelColor} from "../../plant/AlarmService";

type PieChartOfPlantStatusProps = {
  value: Array<MapOfPlantStatusesItem>;
};

type PlantStatusStatisticType = {
  percentages: Array<number>;
  numbers: Array<number>;
  colors: Array<string>;
  labels: Array<string>;
};

function incrementStatistic(
  cache: {[key: string]: {value: number; color: string}},
  label: string,
  color: string,
): void {
  if (label in cache) {
    cache[label].value += 1;
  } else {
    cache[label] = {value: 1, color: color};
  }
}

function plantStatusStatistic(
  data: Array<MapOfPlantStatusesItem>,
  theme: Theme,
): PlantStatusStatisticType {
  const cache: {[key: string]: {value: number; color: string}} = {};
  const res: PlantStatusStatisticType = {
    percentages: [],
    numbers: [],
    colors: [],
    labels: [],
  };
  if (data.length === 0) {
    return res;
  }
  for (const row of data) {
    if (!row.status.isSynchronised) {
      incrementStatistic(cache, "Déconnectée", theme.palette.notSynchronised);
    } else {
      const color = eventLevelColor(row.status.statusType?.level, theme);
      incrementStatistic(cache, row.status.statusType?.label, color);
    }
  }

  for (const prop in cache) {
    res.percentages.push(cache[prop].value);
    res.numbers.push(cache[prop].value);
    res.colors.push(cache[prop].color);
    res.labels.push(prop);
  }

  const nbrPlants = res.numbers.reduce(
    (accumulator, curr) => accumulator + curr,
  );
  res.percentages = res.percentages.map(
    (x) => Math.round(10 * 100 * (x / nbrPlants)) / 10,
  ); // percentage

  return res;
}

export default function PieChartOfPlantStatus(
  props: PieChartOfPlantStatusProps,
) {
  const [chartData, setChartData] = useState<ChartData<"pie">>({
    labels: [],
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState<ChartOptions<"pie">>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const stat = plantStatusStatistic(props.value, theme);
    const data: ChartData<"pie"> = {
      labels: stat.labels,
      datasets: [
        {
          data: stat.percentages,
          backgroundColor: stat.colors,
          borderColor: stat.colors,
          borderWidth: 5,
        },
      ],
    };
    setChartData(data);
    const options: ChartOptions<"pie"> = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: "Etats actuels des installations",
          font: {
            size: 16,
          },
        },
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.parsed.toString() + " % d'installations";
            },
            afterLabel: function (tooltipItem) {
              return stat.numbers[tooltipItem.dataIndex] + " installations";
            },
          },
        },
      },
    };
    setChartOptions(options);
  }, [props.value, theme]);

  return (
    <Card
      className="scada-card"
      style={{
        flex: 1,
        overflow: "hidden",
        marginLeft: isMobile ? "0px" : "20px",
        marginBottom: 0,
        marginTop: isMobile ? "20px" : "0px",
      }}>
      <Pie
        data={chartData}
        options={chartOptions}
        style={{maxHeight: "400px"}}
      />
    </Card>
  );
}
