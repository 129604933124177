import React, {
  ForwardRefRenderFunction,
  RefObject,
  useImperativeHandle,
} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {InterventionDate} from "../../../../../../interfaces/plant/InterventionDate";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../../../store/store";
import {updatePlantInterventionDate} from "../../../../../../store/scada/plant/PlantObjectsSlice";
import locale from "antd/es/date-picker/locale/fr_FR";
import {DatePicker} from "antd";
import dayjs, {type Dayjs} from "dayjs";

type InterventionDateFormHandle = {
  submitForm: () => void;
};

type InterventionDateFormProps = {
  formRef: RefObject<InterventionDateFormHandle>;
  onSuccess: () => void;
  plantId: number;
  interventionDate: string | null;
};

const InterventionDateForm: ForwardRefRenderFunction<
  InterventionDateFormHandle,
  InterventionDateFormProps
> = (props: InterventionDateFormProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const {formRef} = props;

  const {setValue, handleSubmit, trigger} = useForm<InterventionDate>({
    defaultValues: {
      plantId: props.plantId,
      interventionDate: props.interventionDate,
    },
  });

  // permet d appeler le submit form depuis le composant parent
  useImperativeHandle(formRef, () => ({
    submitForm() {
      trigger();
      handleSubmit(onSubmit)();
    },
  }));

  // eslint-disable-next-line
  const onDateChange = (date: Dayjs | null, dateString: string | string[]) => {
    setValue(
      "interventionDate",
      date !== null ? date.format("YYYY-MM-DD") : null,
    );
  };

  const onSubmit: SubmitHandler<InterventionDate> = async (data) => {
    await dispatch(updatePlantInterventionDate(data));
    props.onSuccess();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete={"off"}>
        <DatePicker
          minDate={dayjs()}
          maxDate={dayjs().add(2, "month")}
          defaultValue={
            props.interventionDate === null
              ? props.interventionDate
              : dayjs(props.interventionDate)
          }
          onChange={onDateChange}
          locale={locale}
        />
      </form>
    </>
  );
};

export default React.forwardRef(InterventionDateForm);
