import dayjs from "dayjs";
import {useRef} from "react";
import {Plant} from "../../../../../interfaces/Plant";
import CardRow from "../CardRow";
import TagList from "../../../../../component/TagList";
import InterventionDateForm from "./form/InterventionDateForm";
import {formatDateTime} from "../../../../../service/FormatDateTime";
import {useTheme} from "@mui/material/styles";

interface propsInterface {
  plant: Plant;
}

export default function PlantGeneral(props: propsInterface) {
  const theme = useTheme();

  type CardRowHandle = React.ElementRef<typeof CardRow>;
  const cardRef = useRef<CardRowHandle>(null);

  type InterventionDateFormRef = React.ElementRef<typeof InterventionDateForm>;
  const interventionDateForm = useRef<InterventionDateFormRef>(null);

  const handleDateChange = () => {
    if (interventionDateForm && interventionDateForm.current) {
      interventionDateForm.current.submitForm();
    }
  };
  return (
    <div>
      <div
        style={{
          paddingTop: "10px",
          color:
            theme.palette.mode === "dark"
              ? theme.palette.common.white
              : theme.palette.secondaryFont,
        }}>
        {props.plant.plantAddress}
      </div>

      <h4>Général</h4>

      <CardRow label="Identifiant" isCopyEnable={true}>
        {props.plant.id}
      </CardRow>

      <CardRow label="Agence">{props.plant.agency}</CardRow>

      <CardRow label="Puissance nominale de l'installation">
        {props.plant.nominalPower} W
      </CardRow>

      <CardRow label="Date de mise en route">
        {formatDateTime(props.plant.operationDate)}
      </CardRow>

      <CardRow
        label="Date d'intervention"
        isEditable={true}
        children={
          props.plant.interventionDate
            ? dayjs(new Date(props.plant.interventionDate)).format("DD/MM/YYYY")
            : "Aucune"
        }
        chidrenEditValidate={handleDateChange}
        cardRef={cardRef}
        tooltipTitle="Déclarer ici la date d'intervention prévue permet de filtrer dans la vue parc sur cette information"
        childrenEdit={
          <InterventionDateForm
            plantId={props.plant.id}
            interventionDate={props.plant.interventionDate}
            formRef={interventionDateForm}
            onSuccess={() => {}}
          />
        }></CardRow>

      <CardRow label="Tags">
        <TagList
          tags={props.plant.tags.map((tag) => ({label: tag}))}
          justifyChips={"flex-start"}
          limitTags={2}
        />
      </CardRow>
    </div>
  );
}
