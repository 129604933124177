import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import apiSizea from "../../service/api/ApiSizea";
import {ConverterType} from "../../interfaces/ess/ConverterType";

interface converterTypesState {
  data: ConverterType[];
  loading: boolean;
  error: string | null;
}

const initialState: converterTypesState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchConverterTypes = createAsyncThunk(
  "converterTypes/fetchConverterTypes",
  async () => {
    return apiSizea.converterTypes();
  },
);

const converterTypesSlice = createSlice({
  name: "converterTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConverterTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConverterTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchConverterTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export default converterTypesSlice.reducer;
