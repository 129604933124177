import Box from "@mui/material/Box";
import PrivateRoutes from "../../../PrivateRoutes";

function Implantation() {
  return (
    <Box sx={{display: "flex"}}>
      <PrivateRoutes authField="authAppImplantation" />
    </Box>
  );
}

export default Implantation;
