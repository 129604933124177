import {ReactNode, useState} from "react";
import {Card} from "@mui/material";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {GetAlarmNotViewedBySav} from "../../../interfaces/GetAlarmNotViewedBySav";
import apiV2 from "../../../service/api/ApiV2";
import {DataGrid, GridColDef, useGridApiRef} from "@mui/x-data-grid";
import {Link} from "@mui/material";
import {formatDateTime} from "../../../service/FormatDateTime";
import {formatAlarmLevel} from "../../../service/FormatAlarm";
import AlarmsNotViewedBySavFilters from "./AlarmsNotViewedBySavFilters";
import AlarmsNotViewedBySavFiltersType from "../../../interfaces/alarms/AlarmsNotViewedBySavFiltersType";

function plantLink(plantId: number, value: string): ReactNode {
  return (
    <Link
      href={`/scada/plant?plant_id=${plantId}`}
      color="secondary"
      rel="noopener noreferrer"
      target="_blank">
      {value}
    </Link>
  );
}

export default function AlarmsNotViewedBySavTable() {
  const [rows, setRows] = useState<GetAlarmNotViewedBySav[]>([]);
  const apiRef = useGridApiRef();

  const columns: GridColDef<GetAlarmNotViewedBySav>[] = [
    {
      field: "rank",
      headerName: "",
      width: 70,
      valueGetter: (params) =>
        apiRef.current.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
      sortable: false,
      renderCell: (params) => params.value,
    },
    {
      field: "plantId",
      headerName: "Id site",
      width: 70,
      sortable: false,
      renderCell: (params) => params.value,
    },
    {
      field: "plantName",
      headerName: "Nom Site",
      width: 200,
      sortable: false,
      renderCell: (params) => plantLink(params.row.plantId, params.value),
    },
    {
      field: "alarmReference",
      headerName: "Alarme",
      width: 350,
      sortable: false,
      renderCell: (params) => params.value,
    },
    {
      field: "alarmLevel",
      headerName: "Niveau",
      width: 150,
      sortable: false,
      renderCell: (params) => formatAlarmLevel(params.value),
      cellClassName: (params) => `alarm-lvl-${params.row.alarmLevel}`,
    },
    {
      field: "begin",
      headerName: "Début",
      width: 200,
      sortable: false,
      renderCell: (params) => formatDateTime(params.value),
    },
  ];

  const handleFilterChange = (filters: AlarmsNotViewedBySavFiltersType) => {
    apiV2
      .getAlarmNotViewedBySav(
        filters.plantId,
        filters.begin,
        filters.end,
        filters.references.map((alarmRef) => alarmRef.alarmReference),
        filters.tags,
        filters.interventionDate,
      )
      .then(setRows);
  };

  return (
    <Grid container sx={{backgroundColor: "transparent"}}>
      <Card className="search-card">
        <Typography variant="h6">Liste des alarmes non traitées</Typography>
        <AlarmsNotViewedBySavFilters onFilterChange={handleFilterChange} />
      </Card>

      <Card className="custom-card">
        <DataGrid
          style={{height: 690, margin: 10, boxShadow: "none"}}
          rows={rows}
          columns={columns}
          apiRef={apiRef}
          disableColumnMenu
          hideFooter
          initialState={{
            pagination: {
              paginationModel: {pageSize: 100, page: 0},
            },
          }}
        />
      </Card>
    </Grid>
  );
}
