import {NavLink} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

type GoogleMapLinkProps = {
  coordinates: string;
  label?: string;
  tooltipLabel?: string;
};

const GoogleMapLink = ({
  coordinates,
  label,
  tooltipLabel,
}: GoogleMapLinkProps) => {
  return (
    <Tooltip title={tooltipLabel}>
      <div>
        <NavLink
          to={`https://www.google.com/maps/search/?api=1&query=${coordinates}`}
          target={"_blank"}>
          {label || ""}
        </NavLink>
      </div>
    </Tooltip>
  );
};

export default GoogleMapLink;
