import {IconButton} from "@mui/material";
import sizeaApi from "../../../../service/api/ApiSizea";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  title: string | null;
  file: string;
  faIcon: IconProp;
  type: "simulation" | "source";
}
export default function DownloadFile(props: Props) {
  const download = () => {
    if (props.type === "simulation") {
      sizeaApi.getSimulationFile(props.file as string, props.file as string);
    }
    if (props.type === "source") {
      sizeaApi.getSourceFile(props.file as string, props.file as string);
    }
  };

  return (
    <IconButton
      size="large"
      title={props.title ?? ""}
      onClick={() => {
        download();
      }}>
      <FontAwesomeIcon size="1x" icon={props.faIcon} />
    </IconButton>
  );
}
