import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import * as React from "react";
import Tag from "../interfaces/Tag";
import {ComponentProps} from "react";
import {Grid} from "@mui/material";

type TagListProps = {
  tags: Tag[];
  size?: ComponentProps<typeof Chip>["size"];
  limitTags?: number;
  justifyChips?: ComponentProps<typeof Grid>["justifyContent"];
};

const TagList = ({
  tags,
  size = "small",
  limitTags = 2,
  justifyChips = "center",
}: TagListProps) => {
  const numTags = tags.length;

  if (numTags === 0) {
    return <Box>-</Box>;
  }

  return (
    <Grid
      justifyContent={justifyChips}
      container
      title={tags.map((tag) => tag.label).join(", ")}
      gap={0.5}
      style={{maxWidth: "100%"}}>
      {tags.slice(0, limitTags).map((tag, index) => (
        <Chip
          style={{maxWidth: "100%"}}
          key={index}
          label={tag.label}
          size={size}
        />
      ))}
      {numTags > limitTags && ` +${numTags - limitTags}`}
    </Grid>
  );
};

export default TagList;
