import {AlertColor} from "@mui/material";
import {Subject} from "rxjs";

interface ToastMessage {
  label: string;
  severity: AlertColor;
}

const subject = new Subject<ToastMessage>();
const closeSubject = new Subject<boolean>();

const ToastService = {
  info: function (label: string): void {
    subject.next({
      label: label,
      severity: "info",
    });
  },
  warning: function (label: string): void {
    subject.next({
      label: label,
      severity: "warning",
    });
  },
  error: function (label: string): void {
    subject.next({
      label: label,
      severity: "error",
    });
  },
  success: function (label: string): void {
    subject.next({
      label: label,
      severity: "success",
    });
  },
  close: function (): void {
    closeSubject.next(false);
  },
  subject: function (): Subject<ToastMessage> {
    return subject;
  },
  getCloseSubject: function (): Subject<boolean> {
    return closeSubject;
  },
};

export default ToastService;
