import React, {useRef} from "react";

import {Divider} from "@mui/material";

import CardRow from "../CardRow";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/store";
import {formatDateTime} from "../../../../../service/FormatDateTime";

import SerialNumberForm from "./form/SerialNumberForm";

interface propsInterface {
  index: number;
}

export function TrackerGeneral(props: propsInterface) {
  type SerialNumberHandle = React.ElementRef<typeof SerialNumberForm>;
  type CardRowHandle = React.ElementRef<typeof CardRow>;

  const trackers = useSelector((state: RootState) => state.trackers);
  const formSerialNumber = useRef<SerialNumberHandle>(null);
  const cardRef = useRef<CardRowHandle>(null);

  const handleAction = () => {
    if (formSerialNumber && formSerialNumber.current) {
      formSerialNumber.current.submitForm();
    }
  };

  const handleSuccess = () => {
    if (cardRef && cardRef.current) {
      cardRef.current.setIsEditing(false);
    }
  };

  return (
    <div>
      <h4>Général</h4>

      <CardRow isCopyEnable={true} label="Identifiant">
        {trackers.data[props.index].id}
      </CardRow>

      <CardRow
        label="Numéro de série"
        isEditable={true}
        children={trackers.data[props.index].serialNumber}
        chidrenEditValidate={handleAction}
        cardRef={cardRef}
        childrenEdit={
          <SerialNumberForm
            formRef={formSerialNumber}
            index={props.index}
            onSuccess={handleSuccess}
          />
        }></CardRow>

      <CardRow label="Type de tracker">
        {trackers.data[props.index].trackerTypeLabel}
      </CardRow>
      <CardRow label="Taille">{trackers.data[props.index].size} m2</CardRow>
      <CardRow label="Mise en route">
        {formatDateTime(trackers.data[props.index].operationDate)}
      </CardRow>

      <div style={{marginTop: "20px"}}>
        <Divider></Divider>
      </div>

      <h4>Logiciel</h4>

      <CardRow label="Okwind App">
        {trackers.data[props.index].okwindAppVersion}
      </CardRow>
      <CardRow label="Kernel">
        {trackers.data[props.index].kernelVersion}
      </CardRow>
      {trackers.data[props.index].jfrogDeviceUuid && (
        <CardRow isCopyEnable={true} label="JFrog UUID">
          {trackers.data[props.index].jfrogDeviceUuid}
        </CardRow>
      )}
      {trackers.data[props.index].jfrogDeviceId && (
        <CardRow isCopyEnable={true} label="JFrog identifiant">
          {trackers.data[props.index].jfrogDeviceId}
        </CardRow>
      )}
    </div>
  );
}
