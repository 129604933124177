import * as React from "react";
import {useTheme} from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import apiV2 from "../../../service/api/ApiV2";
import ToastService from "../../../service/ToastService";
import {Grid} from "@mui/material";
import {Divider} from "antd";
import {AlarmType} from "../../../interfaces/AlarmType";

class PayloadListAlarmId {
  listAlarmsId: Array<string> = [];
}

const CardRestartByAlarmAction = (props: {
  selectedRow: Array<AlarmType> | null;
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = () => {
    const data = new PayloadListAlarmId();
    if (props.selectedRow != null) {
      props.selectedRow.forEach((element: AlarmType) => {
        data.listAlarmsId.push(element.id);
      });
    }
    ToastService.success("Redémarrage en masse demandé");
    apiV2.restartByAlarm(data);
    setOpen(false);
  };

  const disabledHandleSubmit = () => {
    if (props.selectedRow != null) {
      if (props.selectedRow.length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getConformSelectedRow = () => {
    return props.selectedRow != null ? props.selectedRow : [];
  };

  return (
    <Card style={{width: "100%", borderRadius: "8px", boxShadow: "none"}}>
      <Toolbar>
        <SettingsRemoteIcon style={{marginRight: 15}} /> Action à distance en
        fonction des alarmes
      </Toolbar>
      <Divider style={{marginTop: "0px"}} />
      <CardContent>
        <Grid item xs={12}>
          <List
            sx={{
              width: "100%",
              marginTop: "-25px",
            }}
            subheader={<li />}>
            <ListSubheader>Actions disponibles</ListSubheader>
            <Button
              onClick={handleOpen}
              disabled={disabledHandleSubmit()}
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "15px",
              }}
              variant="contained"
              type="submit"
              title="Redemarrage tracking">
              Redémarrer le tracking
            </Button>
          </List>
        </Grid>
        <Grid item xs={12}>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              maxHeight: 450,
              "& ul": {padding: 0},
            }}
            subheader={<li />}>
            <ListSubheader
              style={{
                marginBottom: "10px",
              }}>{`Listes des alarmes selectionnées`}</ListSubheader>
            {disabledHandleSubmit() && (
              <Typography style={{marginTop: "-5px", marginLeft: "20px"}}>
                Aucune alarme selectionnée
              </Typography>
            )}
            {getConformSelectedRow().map((item) => (
              <li key={`section-${item.id}`}>
                <ul>
                  <ListItem
                    style={{marginTop: "-20px", marginBottom: "-20px"}}
                    key={`item-${item.id}`}>
                    <ListItemText primary={`${item.label}`} />
                  </ListItem>
                  <Divider></Divider>
                </ul>
              </li>
            ))}
          </List>
        </Grid>
      </CardContent>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <Toolbar style={{backgroundColor: theme.palette.toolbarBackground}}>
          <ReportProblemIcon style={{marginRight: 15}} />
          Confirmation du redémarrage
        </Toolbar>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>
              Vous êtes sur le point de faire une action de redémarrage de masse
              avec la sélection de
              <b> {getConformSelectedRow().length}</b>{" "}
              {getConformSelectedRow().length === 1 ? " alarme" : " alarmes"}.
              <br />
            </span>
            <span>Etes-vous sûr de vouloir effectuer cette action ?</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            type="submit"
            autoFocus>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default CardRestartByAlarmAction;
