import {useRef} from "react";
import {Divider} from "@mui/material";
import {Plant} from "../../../../../interfaces/Plant";
import CardRow from "../CardRow";
import MapAddress from "../../../../../component/form/MapAddress";
import {formatConsumptionLevel} from "../../../../../service/FormatConsumptionLevel";
import ConsumptionLevelForm from "./form/ConsumptionLevelForm";

interface propsInterface {
  plant: Plant;
}

export default function PlantSetting(props: propsInterface) {
  type ConsumptionLevelFormRef = React.ElementRef<typeof ConsumptionLevelForm>;
  const consumptionLevelForm = useRef<ConsumptionLevelFormRef>(null);

  const handleConsumptionLevelSubmit = () => {
    if (consumptionLevelForm && consumptionLevelForm.current) {
      consumptionLevelForm.current.submitForm();
    }
  };

  return (
    <div
      style={{
        maxHeight: "300px",
        overflow: "auto",
      }}>
      <h4>Configuration logiciel</h4>
      <CardRow label="Bridage activé">
        {props.plant.restrictedPower
          ? "Oui"
          : props.plant.restrictedPower === false
            ? "Non"
            : ""}
      </CardRow>
      {props.plant.restrictedPower && (
        <CardRow label="Valeur de bridage">
          {props.plant.restrictedValue !== null
            ? props.plant.restrictedValue / 1000 + " kW"
            : ""}
        </CardRow>
      )}
      <CardRow
        label="Mesure de consommation"
        isEditable={true}
        chidrenEditValidate={handleConsumptionLevelSubmit}
        childrenEdit={
          <ConsumptionLevelForm
            plantId={props.plant.id}
            consumptionLevel={props.plant.consumptionLevel}
            formRef={consumptionLevelForm}></ConsumptionLevelForm>
        }>
        {formatConsumptionLevel(props.plant.consumptionLevel)}
      </CardRow>

      <CardRow label="Méthode de calcul">
        {props.plant.energyCalculationMethod ? (
          <span>Calcul en énergie (Wh)</span>
        ) : (
          <span>Calcul en puissance (Watt)</span>
        )}
      </CardRow>

      <div style={{marginTop: "20px"}}>
        <Divider></Divider>
      </div>

      <h4>Coordonnées GPS</h4>

      <CardRow label="Latitude">{props.plant.latitude}</CardRow>
      <CardRow label="Longitude">{props.plant.longitude}</CardRow>

      <div style={{marginTop: "20px"}}>
        <Divider></Divider>
        <h4>Carte</h4>
        <MapAddress
          latitude={Number(props.plant.latitude)}
          longitude={Number(props.plant.longitude)}
          precision={2}
          mapWidth="99%"
          mapHeight="330px"
        />
      </div>
    </div>
  );
}
