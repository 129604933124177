import {InterventionDateFilterEnum} from "../../interfaces/plant/InterventionDateFilterEnum";

const InterventionDateFilterOptions = [
  {
    id: InterventionDateFilterEnum.NO_OR_PAST,
    label: "Installations sans date d'intervention",
  },
  {
    id: InterventionDateFilterEnum.FUTURE,
    label: "Installations avec intervention planifiée",
  },
];
export default InterventionDateFilterOptions;
