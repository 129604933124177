import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useTheme} from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import {Checklist} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import {AppDispatch, RootState} from "../../../../store/store";
import {fetchPlants} from "../../../../store/control/plants.store";
import CustomNoRowsOverlay from "../../../../component/CustomNoRowsOverlay";
import Plant from "../../../../interfaces/control/Plant";
import PlantStatusTag from "../../../../component/PlantStatusTag";
import {
  formatCountdown,
  formatDate,
  formatDateTime,
} from "../../../../service/FormatDateTime";
import PlantsToControlFilters from "./PlantsToControlFilters";
import {PlantFilters} from "../../../../interfaces/control/PlantFilters";
import ToastService from "../../../../service/ToastService";

const PlantsToControlView = () => {
  const theme = useTheme();
  const {data: plantsToControl, error} = useSelector(
    (state: RootState) => state.plantsToControl,
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const columns = [
    {id: "status", label: "Statut"},
    {id: "name", label: "Installation"},
    {id: "agency_name", label: "Agence"},
    {id: "operation_date", label: "Mise en route"},
    {id: "checkpoints_count", label: "Contrôles"},
    {id: "control_date", label: "Date de contrôle"},
    {id: "action", label: "Actions"},
  ];

  const handleFilterChange = (filters: PlantFilters) => {
    dispatch(fetchPlants(filters));
  };

  useEffect(() => {
    if (error) {
      ToastService.error(error);
    }
  }, [error]);

  return (
    <Grid container sx={{backgroundColor: "transparent"}}>
      <Card className="search-card">
        <PlantsToControlFilters onFilterChange={handleFilterChange} />
      </Card>

      <Card className={"custom-card"} style={{maxHeight: "75vh"}}>
        <Grid
          container
          sx={{backgroundColor: "transparent", pl: 0, pr: 0, height: "100%"}}>
          <Grid item xs={12} style={{padding: "10px", height: "100%"}}>
            {plantsToControl.length === 0 ? (
              <CustomNoRowsOverlay
                noRowText="Pas d'installations à contrôler"
                loading={null}
              />
            ) : (
              <TableContainer sx={{boxShadow: "none", maxHeight: "100%"}}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, i) => (
                        <TableCell
                          key={i}
                          align={
                            column.id === "status" || column.id === "action"
                              ? "center"
                              : "left"
                          }
                          style={{fontWeight: "bold"}}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {plantsToControl.map((plantToControl: Plant) => (
                      <TableRow
                        hover
                        key={plantToControl.id}
                        sx={{
                          "&:last-child td, &:last-child th": {border: 0},
                        }}>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}>
                            <PlantStatusTag status={plantToControl.status} />
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <NavLink
                            to={`/fleet/control?plant_id=${plantToControl.id}`}>
                            <Typography
                              color={"secondary"}
                              style={{
                                textDecoration: "underline",
                                display: "inline",
                              }}>
                              {plantToControl.name}
                            </Typography>
                          </NavLink>
                          {plantToControl.serial_number !== "" && (
                            <Typography variant={"body2"}>
                              {plantToControl.serial_number}
                            </Typography>
                          )}
                          <Typography
                            color={"text.secondary"}
                            fontSize={"small"}>
                            {plantToControl.formatted_address}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {plantToControl.agency_name}
                        </TableCell>
                        <TableCell align="left">
                          {formatDateTime(plantToControl?.operation_date)}
                          <br />
                          {formatCountdown(plantToControl?.operation_date)}
                        </TableCell>
                        <TableCell align={"left"}>
                          {plantToControl.total_checkpoint_count !== 0 ? (
                            <span>
                              {plantToControl.ok_checkpoint_count} sur{" "}
                              {plantToControl.total_checkpoint_count}
                            </span>
                          ) : (
                            <span>N/A</span>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {formatDate(plantToControl.last_control_date)}
                          <br />
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}>
                            <Tooltip
                              title={
                                "Aller aux points de contrôle de l'installation"
                              }>
                              <IconButton
                                color="secondary"
                                aria-label="go to installation checkpoints"
                                onClick={() =>
                                  navigate(
                                    `/fleet/control?plant_id=${plantToControl.id}`,
                                  )
                                }>
                                <Checklist
                                  style={{
                                    color:
                                      theme.palette.mode === "dark"
                                        ? theme.palette.common.white
                                        : theme.palette.common.black,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default PlantsToControlView;
