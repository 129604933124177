import {Tracker} from "../../../../interfaces/Tracker";
import TrackerCard from "./tracker/TrackerCard";

type TrackerCardsProps = {
  trackers: {isSelected: boolean; tracker: Tracker}[];
};

export function TrackerCards(props: TrackerCardsProps) {
  return (
    <>
      {props.trackers.map((tracker) => {
        return (
          <TrackerCard
            key={tracker.tracker.id}
            tracker={tracker.tracker}
            isSelected={tracker.isSelected}
          />
        );
      })}
    </>
  );
}
