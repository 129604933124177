import authenticatedApi from "../AuthenticatedApi";
import Simulation, {
  HotWaterTankTypeRef,
  Placeholder,
  Template,
} from "../../app/pre-sale/sizea/model";
import {ImmersionHeaterType} from "../../app/pre-sale/sizea/model";
import {TrackerType} from "../../interfaces/TrackerType";
import {ConverterType} from "../../interfaces/ess/ConverterType";
import {EssType} from "../../interfaces/ess/EssType";
import {BatteryType} from "../../interfaces/ess/BatteryType";
import {SimulationDto, UserDto} from "../../app/pre-sale/sizea/dto";
import {FilterInterface} from "../../app/pre-sale/sizea/SimulationsFilter";
import {REACT_APP_API_SIZEA_URL} from "../../config";
import ToastService from "../ToastService";

const baseUrl = REACT_APP_API_SIZEA_URL;
const sizeaSimulationEndpoint = "simulation_requests";
const sizeaMediaEndpoint = "media_objects";
const sizeaSimulation = "simulation";
const sizeaSimulations = "simulations";
const sizeaImmersionHeaterTypesEndpoint = "reference/immersion_heater_types";
const sizeaTrackerTypesEndpoint = "reference/tracker_types";
const sizeaConverterTypesEndpoint = "reference/converter_types";
const sizeaBatteryTypesEndpoint = "reference/battery_types";
const sizeaEssTypesEndpoint = "reference/ess_types";
const sizeaPlaceholder = "reference/placeholders";
const sizeaTemplates = "templates";
const sizeaCustomTemplateEndpoint = "upload_templates";
const sizeaUserEndpoint = "users";
const sizeaMapImageEndpoint = "upload_map_images";

export const DATE_FORMAT_ZULU: string = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

function toJson(response: Response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
}

async function fetchData<T>(
  url: string,
  defaultValue: T,
  errorTip: string,
): Promise<T> {
  try {
    const response = await authenticatedApi.get(url);
    const data = await toJson(response);
    return data;
  } catch (error) {
    console.error(`There was a problem with get ${url}`, error);
    ToastService.error("Le chargement a échoué pour : " + errorTip);
    return defaultValue;
  }
}

const sizeaApi = {
  simulate: async function (simulation: Simulation) {
    const url = `${baseUrl}/${sizeaSimulationEndpoint}`;

    return authenticatedApi.post(url, simulation).then((response) => {
      if (response.status !== 202) {
        throw new Error(response.statusText);
      }
    });
  },
  getUploadUrl: function () {
    return `${baseUrl}/${sizeaMediaEndpoint}`;
  },
  getUploadHeaders: function () {
    return authenticatedApi.getAuthorizationHeaders();
  },
  /**
   * @param hotWaterTankTypeRef CUVE ou BALLON
   */
  immersionHeaterTypes: async function (
    hotWaterTankTypeRef: HotWaterTankTypeRef,
  ): Promise<Array<ImmersionHeaterType>> {
    const url = `${baseUrl}/${sizeaImmersionHeaterTypesEndpoint}?type=${hotWaterTankTypeRef}`;
    return fetchData(url, [], "Types de thermoplongeurs");
  },
  trackerTypes: async function (): Promise<Array<TrackerType>> {
    const url = `${baseUrl}/${sizeaTrackerTypesEndpoint}`;
    return fetchData(url, [], "Types de trackers");
  },
  converterTypes: async function (): Promise<Array<ConverterType>> {
    const url = `${baseUrl}/${sizeaConverterTypesEndpoint}`;
    return fetchData(url, [], "Types de contrôleurs");
  },
  batteryTypes: async function (): Promise<Array<BatteryType>> {
    const url = `${baseUrl}/${sizeaBatteryTypesEndpoint}`;
    return fetchData(url, [], "Types de batteries");
  },
  users: async function (): Promise<Array<UserDto>> {
    const url = `${baseUrl}/${sizeaUserEndpoint}`;
    return fetchData(url, [], "Utilisateurs");
  },
  essTypes: async function (): Promise<Array<EssType>> {
    const url = `${baseUrl}/${sizeaEssTypesEndpoint}`;
    return fetchData(url, [], "Types d'ESS");
  },
  getSimulationFile: async function (
    simulationFilename: string,
    filename: string,
  ) {
    const url = `${baseUrl}/${sizeaSimulation}/${simulationFilename}`;

    return authenticatedApi
      .get(url)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response.blob();
      })
      .then((data) => {
        const anchor = document.createElement("a");
        anchor.href = window.URL.createObjectURL(data);
        anchor.download = `${filename}`;
        anchor.click();
      });
  },
  getSourceFile: async function (sourceFilename: string, filename: string) {
    const url = `${baseUrl}/source/${sourceFilename}`;

    return authenticatedApi
      .get(url)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response.blob();
      })
      .then((data) => {
        const anchor = document.createElement("a");
        anchor.href = window.URL.createObjectURL(data);
        anchor.download = `${filename}`;
        anchor.click();
      });
  },
  getExampleFile: async function (exampleIdentifier: string) {
    const url = `${baseUrl}/${sizeaMediaEndpoint}/${exampleIdentifier}`;

    return authenticatedApi
      .get(url)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response.blob();
      })
      .then((data) => {
        const anchor = document.createElement("a");
        anchor.href = window.URL.createObjectURL(data);
        anchor.download = exampleIdentifier;
        anchor.click();
      });
  },
  getSimulations: async function (
    filter: FilterInterface,
  ): Promise<Array<SimulationDto>> {
    let url = `${baseUrl}/${sizeaSimulations}?filter`;

    if (filter.userId) {
      url += `&userId=${filter.userId}`;
    }
    if (filter.search && filter.search.length > 2) {
      url += `&search=${filter.search}`;
    }
    if (filter.startDate) {
      url += `&date[after]=${filter.startDate.format(DATE_FORMAT_ZULU)}`;
    }
    if (filter.endDate) {
      // Ajouter 1 jour pour l'inclure dans la recherche
      let endDate = filter.endDate.clone();
      endDate = endDate.add(1, "day");
      url += `&date[before]=${endDate.format(DATE_FORMAT_ZULU)}`;
    }

    return authenticatedApi.get(url).then((response) => response.json());
  },
  placeholders: async function (): Promise<Array<Placeholder>> {
    const url = `${baseUrl}/${sizeaPlaceholder}`;
    return fetchData(url, [], "Placeholders");
  },
  getUploadCustomTemplateUrl: function () {
    return `${baseUrl}/${sizeaCustomTemplateEndpoint}`;
  },
  getTemplates: async function (): Promise<Array<Template>> {
    const url = `${baseUrl}/${sizeaTemplates}`;
    return fetchData(url, [], "Templates");
  },
  getTemplateFile: async function (templateId: string) {
    const url = `${baseUrl}/${sizeaTemplates}/${templateId}`;

    return authenticatedApi
      .get(url)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response.blob();
      })
      .then((data) => {
        const anchor = document.createElement("a");
        anchor.href = window.URL.createObjectURL(data);
        anchor.download = templateId;
        anchor.click();
      });
  },
  uploadMapImage: async function (filename: string, blob: Blob) {
    const url = `${baseUrl}/${sizeaMapImageEndpoint}`;
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("fileName", filename);

    return authenticatedApi
      .postFormData(url, formData, false)
      .then((response) => {
        if (response.status !== 201) {
          throw new Error(response.statusText);
        }
      });
  },
};

export default sizeaApi;
