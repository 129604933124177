import dayjs, {Dayjs} from "dayjs";
import {Plant} from "../../../interfaces/Plant";
import ToastService from "../../../service/ToastService";

export function generateDates(startDate: Dayjs, endDate: Dayjs): string[] {
  const timestepMinute = 5;
  let currentDate = startDate
    .second(0)
    .minute(Math.round(startDate.minute() / timestepMinute) * timestepMinute);
  endDate = endDate
    .second(0)
    .minute(Math.round(startDate.minute() / timestepMinute) * timestepMinute);
  const dates = [];
  while (currentDate.isBefore(endDate)) {
    dates.push(currentDate.format("YYYY-MM-DDTHH:mm:ss+00:00"));
    currentDate = currentDate.add(timestepMinute, "minute");
  }
  return dates;
}

export function formatXLabels(dates: string[], plant: Plant): string[] {
  return dates.map((x) =>
    dayjs(x).tz(plant.timezone).format("YYYY-MM-DD HH:mm:ss"),
  );
}

export function checkMaxPeriodAllowed(
  startDate: Dayjs,
  endDate: Dayjs,
): boolean {
  const maxPeriodAllowed = 7; // in days
  if (endDate.diff(startDate, "day") > maxPeriodAllowed) {
    ToastService.error(`La période demandée est trop longue`);
    return false;
  }
  return true;
}

export function checkEnDateGtStartDate(
  startDate: Dayjs,
  endDate: Dayjs,
): boolean {
  if (endDate.diff(startDate, "day") < 0) {
    ToastService.error(
      `La date de fin doit être supérieure à la date de début`,
    );
    return false;
  }
  return true;
}

export function checkDateFormat(date: Dayjs): boolean {
  if (!date.get("date")) {
    ToastService.error(`Format de date non supporté`);
    return false;
  }
  return true;
}

export function checkDates(startDate: Dayjs, endDate: Dayjs): boolean {
  if (!checkDateFormat(startDate)) {
    return false;
  }

  if (!checkDateFormat(endDate)) {
    return false;
  }

  if (!checkEnDateGtStartDate(startDate, endDate)) {
    return false;
  }

  if (!checkMaxPeriodAllowed(startDate, endDate)) {
    return false;
  }

  return true;
}
