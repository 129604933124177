import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  darkMode: localStorage.getItem("darkMode")
    ? !!JSON.parse(localStorage.getItem("darkMode") || "{}")
    : false,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.darkMode = !state.darkMode;
      localStorage.setItem("darkMode", state.darkMode.toString());
    },
  },
});

export const {toggleTheme} = themeSlice.actions;

export default themeSlice.reducer;
