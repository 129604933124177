import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

interface propsInterface {
  label: string;
  btnTitle: string;
  disabled?: boolean | false;
  icon: React.ReactNode;
  handleClick: () => void;
}

export function PlantAction(props: propsInterface) {
  return (
    <Grid container sx={{mt: "10px"}}>
      <Grid item xs={9} className="item-feature">
        {props.label}
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          size="small"
          disabled={props.disabled}
          onClick={() => {
            props.handleClick();
          }}
          endIcon={props.icon}>
          {props.btnTitle}
        </Button>
      </Grid>
    </Grid>
  );
}
