import ReactGA from "react-ga4";
import {useState} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import ToastService from "../service/ToastService";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import DownloadFileXMLHttp from "../service/file/DownloadFileXMLHttp";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

function CircularProgressWithLabel(
  props: CircularProgressProps & {value: number},
) {
  return (
    <Box
      sx={{
        marginLeft: "10px",
        marginRight: "10px",
        position: "relative",
        display: "inline-flex",
      }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Typography
          variant="caption"
          component="div"
          color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

type FileProps = {
  icon: boolean;
  url: string;
  filename: string;
};

export default function DownloadButton(props: FileProps) {
  const [progress, setProgress] = useState<number>(0.0);

  const handleDownload = () => {
    DownloadFileXMLHttp.download(props.url, props.filename).then((xmlHTTP) => {
      let progressValue = 0;
      xmlHTTP.onprogress = (progressHttp) => {
        progressValue = (progressHttp.loaded / progressHttp.total) * 100;
        setProgress(progressValue);
        if (progressValue >= 100) {
          ToastService.success("Téléchargement terminé avec succès");
        }
      };
      xmlHTTP.onerror = () => {
        ToastService.error("Erreur durant le téléchargement");
        setProgress(0);
      };
    });
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Download : " + props.filename,
    });
  };

  const isDownloading = () => {
    return progress > 0 && progress < 100;
  };

  return (
    <div style={{margin: "0px"}}>
      {props.icon ? (
        <Tooltip title={"Télécharger: " + props.filename}>
          <IconButton onClick={handleDownload} disabled={isDownloading()}>
            {isDownloading() ? (
              <CircularProgress size={24} />
            ) : (
              <CloudDownloadIcon />
            )}
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          disabled={isDownloading()}
          id="downloadConfiguratorLink"
          style={{
            float: "right",
            marginRight: "10px",
            marginTop: "5px",
            marginBottom: "-5px",
          }}
          variant="outlined"
          onClick={handleDownload}
          endIcon={
            isDownloading() ? (
              <CircularProgressWithLabel size="2.9rem" value={progress} />
            ) : (
              <CloudDownloadIcon />
            )
          }>
          Télécharger
        </Button>
      )}
    </div>
  );
}
