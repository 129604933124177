import CardRow from "../CardRow";

export default function ImmersionHeaterSetting() {
  return (
    <div>
      <h4>Configuration logiciel</h4>

      <CardRow label="Type de connexion">
        <i>Bientôt disponible</i>
      </CardRow>

      <CardRow label="Adresse">
        <i>Bientôt disponible</i>
      </CardRow>

      <CardRow label="Alias">
        <i>Bientôt disponible</i>
      </CardRow>
    </div>
  );
}
