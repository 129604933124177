import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import SynchronisedDiv from "../SynchronisedDiv";
import StatusDiv from "../StatusDiv";
import {useEffect, useState} from "react";
import apiV2 from "../../../../../service/api/ApiV2";
import {forkJoin} from "rxjs";
import {ImmersionHeater} from "../../../../../interfaces/ImmersionHeater";
import {ImmersionHeaterStatus} from "../../../../../interfaces/ImmersionHeaterStatus";
import {AlarmsList} from "../AlarmsList";
import AppCollapse from "../AppCollapse";
import {CardMedia, Checkbox, Grid, IconButton, Tooltip} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import image from "../../../../../assets/icon_immersion_heater.svg";
import {useAppDispatch} from "../../../../../store/hooks";
import {toggleImmersionHeater} from "../../../../../store/scada/plant/PlantObjectsSlice";
import {useTheme} from "@mui/material/styles";
import ImmersionHeaterDialog from "./ImmersionHeaterDialog";

type ImmersionHeaterCardProps = {
  immersionHeater: ImmersionHeater;
  isSelected: boolean;
};

export default function ImmersionHeaterCard(props: ImmersionHeaterCardProps) {
  const [immersionHeaterStatus, setImmersionHeaterStatus] =
    useState<ImmersionHeaterStatus>();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function toggleSelect(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(
      toggleImmersionHeater({
        id: props.immersionHeater.id,
        isSelected: event.target.checked,
      }),
    );
  }

  useEffect(() => {
    const status = forkJoin({
      immersionHeaterStatus: apiV2.immersionHeaterStatus(
        props.immersionHeater.id,
      ),
    });

    status.subscribe({
      next: (results) => {
        setImmersionHeaterStatus(results.immersionHeaterStatus);
      },
    });
  }, [props.immersionHeater]);

  if (!immersionHeaterStatus) return <></>;
  return (
    <Card className="scada-card">
      <Grid container style={{paddingTop: "15px", paddingLeft: "10px"}}>
        <Grid item xs={2}>
          <CardMedia
            style={{
              backgroundColor:
                theme.palette.mode === "dark" ? "#757575" : "transparent",
              borderRadius: theme.palette.mode === "dark" ? "50%" : "0px",
              padding: "2px",
              marginLeft: "15px",
            }}
            className="scada-card-image"
            component="img"
            image={image}
          />
        </Grid>
        <Grid item xs={8} style={{paddingLeft: "10px"}}>
          <Typography component="div" className="scada-card-title">
            {props.immersionHeater.alias}
          </Typography>
          <SynchronisedDiv
            isSynchronised={immersionHeaterStatus.isSynchronised}
          />
          {immersionHeaterStatus.isSynchronised && (
            <StatusDiv statusType={immersionHeaterStatus.statusType} />
          )}
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            marginTop: "-5px",
            paddingRight: "10px",
            textAlign: "right",
          }}>
          <Checkbox onChange={toggleSelect} checked={props.isSelected} />
          <Tooltip title="Paramètres">
            <IconButton aria-label="delete" onClick={handleOpen}>
              <SettingsIcon fontSize="inherit" color="secondary" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            paddingLeft: "10px",
            paddingRight: "18px",
            paddingBottom: "10px",
          }}>
          <AppCollapse title="Alarmes">
            {immersionHeaterStatus.alarms.length > 0 ? (
              <AlarmsList alarms={immersionHeaterStatus.alarms} />
            ) : (
              <p>Aucune alarmes</p>
            )}
          </AppCollapse>
        </Grid>
      </Grid>
      <ImmersionHeaterDialog
        immersionHeater={props.immersionHeater}
        open={open}
        handleClose={handleClose}></ImmersionHeaterDialog>
    </Card>
  );
}
