import {SelectType} from "./SelectType";
import {FieldValues, UseControllerProps} from "react-hook-form";
import {SxProps} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../store/store";
import {fetchConverterTypes} from "../../../../store/form/converterTypes.store";
import {useEffect} from "react";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error: boolean | undefined;
  sx: SxProps;
}

export function SelectConverterType<T extends FieldValues>(props: Props<T>) {
  const dispatch = useDispatch<AppDispatch>();
  const converterTypes = useSelector(
    (state: RootState) => state.converterTypes,
  );

  useEffect(() => {
    if (converterTypes.data.length === 0) dispatch(fetchConverterTypes());
  }, []);

  return (
    <SelectType
      types={converterTypes.data}
      sx={props.sx}
      error={props.error}
      control={props.control}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
}
