import {StatusCodes} from "http-status-codes";

import keycloak from "../Keycloak";
import LoadService from "./LoadService";
import ToastService from "./ToastService";

// service qui compte le nombre d appel WS en cours et affiche ou non le loader global
let requestCount = 0;
const loadCounter = {
  add: function (globalLoader: boolean = true) {
    requestCount++;
    if (globalLoader) this.apply();
  },
  remove: function () {
    requestCount--;
    this.apply();
  },
  apply: function () {
    if (requestCount > 0) {
      LoadService.open();
    } else {
      LoadService.close();
    }
  },
};

const notifyStatusError = (response: Response) => {
  switch (response.status) {
    case StatusCodes.FORBIDDEN:
      ToastService.error("Vous n'avez pas les droits pour faire cette action");
      break;
    case StatusCodes.TOO_MANY_REQUESTS:
      ToastService.error(
        "Vous avez effectué trop de requêtes envers notre serveur",
      );
      break;
    case StatusCodes.INTERNAL_SERVER_ERROR:
      ToastService.error(
        "Une erreur serveur est survenue, contactez l'administrateur de la plateforme",
      );
      break;
    default:
      break;
  }
};

const request = (
  url: string,
  method: string,
  headers: HeadersInit,
  body: object | null,
  isFormData: boolean = false,
  globalLoader: boolean = true,
) => {
  loadCounter.add(globalLoader);
  const responsePromise: Promise<Response> = new Promise((resolve) => {
    const response = fetch(url, {
      method: method,
      headers: headers,
      body: body && !isFormData ? JSON.stringify(body) : (body as FormData),
    }).finally(() => loadCounter.remove());

    response.then(notifyStatusError);
    resolve(response);

    response.catch(() => {
      ToastService.error(
        "Une erreur est survenue, impossible de réaliser l'action",
      );
    });
  });

  return responsePromise;
};

const authenticatedApi = {
  post: async function (
    url: string,
    body: object | null,
    globalLoader: boolean = true,
  ): Promise<Response> {
    return request(
      url,
      "POST",
      {
        Accept: "application/json",
        "Content-Type": "application/ld+json",
        Authorization: "Bearer " + keycloak.token,
        OkWindClient: "APP_ADMIN_CLIENT",
      },
      body,
      false,
      globalLoader,
    );
  },

  postFormData: async function (
    url: string,
    body: FormData,
    globalLoader: boolean = true,
  ): Promise<Response> {
    return request(
      url,
      "POST",
      {
        Accept: "application/json",
        Authorization: "Bearer " + keycloak.token,
        OkWindClient: "APP_ADMIN_CLIENT",
      },
      body,
      true,
      globalLoader,
    );
  },

  patch: async function (
    url: string,
    body: object | null,
    globalLoader: boolean = true,
  ): Promise<Response> {
    return request(
      url,
      "PATCH",
      {
        Accept: "application/json",
        "Content-Type": "application/ld+json",
        Authorization: "Bearer " + keycloak.token,
        OkwindClient: "APP_ADMIN_CLIENT",
      },
      body,
      false,
      globalLoader,
    );
  },

  get: async function (
    url: string,
    globalLoader: boolean = true,
  ): Promise<Response> {
    return request(
      url,
      "GET",
      {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.token,
        OkwindClient: "APP_ADMIN_CLIENT",
      },
      null,
      false,
      globalLoader,
    );
  },

  getImage: async function (url: string): Promise<string> {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        OkwindClient: "APP_ADMIN_CLIENT",
      },
    });
    if (!response.ok) {
      throw new Error(`Erreur HTTP: ${response.status}`);
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  },

  getAuthorizationHeaders: function () {
    return {
      Authorization: "Bearer " + keycloak.token,
      "X-Requested-With": null,
      OkwindClient: "APP_ADMIN_CLIENT",
    };
  },
};

export default authenticatedApi;
