import apiSizea from "../../../../service/api/ApiSizea";
import {useEffect, useState} from "react";
import {MenuItem, Select, SelectChangeEvent, SelectProps} from "@mui/material";
import {TrackerType} from "../../../../interfaces/TrackerType";
import {RegisterOptions, UseFormRegister} from "react-hook-form";
import Simulation from "../model";

interface SelectTrackerTypeSpecificProps {
  onTrackerChange: (value: TrackerType) => void;
  register: UseFormRegister<Simulation>;
  registeroptions?: RegisterOptions<Simulation>;
  name: `productionInput.trackerInputs.${number}.trackerTypeId`;
}

type SelectTrackerTypeProps = SelectProps<string | null> &
  SelectTrackerTypeSpecificProps;

export function SelectTrackerType(props: SelectTrackerTypeProps) {
  const [trackerTypes, setTrackerTypes] = useState<{
    [key: string]: TrackerType;
  }>({});

  useEffect(() => {
    apiSizea.trackerTypes().then((data) => {
      const trackerTypesObj: {[key: string]: TrackerType} = {};
      data.forEach((trackerType) => {
        trackerTypesObj[trackerType.id] = trackerType;
      });
      setTrackerTypes(trackerTypesObj);
    });
  }, []);

  if (Object.keys(trackerTypes).length === 0) {
    return <></>;
  }

  // récupérer les props du select en isolant la propriété register, qui sera étendu à part
  const {register, registeroptions, name, error, label, labelId, defaultValue} =
    props;
  const {onChange: registeredOnChange, ...otherRegisterProps} = register(
    name,
    registeroptions,
  );

  const handleChange = (event: SelectChangeEvent<string | null>): void => {
    const value = event.target.value as string;
    const tracker = trackerTypes[value];

    if (registeredOnChange) {
      registeredOnChange(event);
    }
    props.onTrackerChange(tracker);
  };

  return (
    <Select<string | null>
      labelId={labelId}
      onChange={handleChange}
      error={error}
      label={label}
      defaultValue={defaultValue}
      {...otherRegisterProps}>
      {Object.values(trackerTypes).map((trackerType: TrackerType, idx) => (
        <MenuItem key={idx} value={trackerType.id}>
          {trackerType.label}
        </MenuItem>
      ))}
    </Select>
  );
}
