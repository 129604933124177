import * as React from "react";
import {Grid} from "@mui/material";
import ListTrackerAlarmType from "./ListTrackerAlarmType";
import CardRestartByAlarmAction from "./CardRestartByAlarmAction";
import {AlarmType} from "../../../interfaces/AlarmType";

export default function ScadaRestartByAlarmView() {
  const [selectedRow, setSelectedRow] = React.useState<Array<AlarmType> | null>(
    null,
  );

  const getListAlarmSelected = (selectedRow: Array<AlarmType> | null): void => {
    setSelectedRow(selectedRow);
  };

  return (
    <Grid
      container
      spacing={4}
      style={{backgroundColor: "transparent", padding: 15}}>
      <Grid item container xs={12} md={6} lg={8}>
        <ListTrackerAlarmType
          getListAlarmSelected={getListAlarmSelected}></ListTrackerAlarmType>
      </Grid>
      <Grid item container xs={12} md={6} lg={4}>
        <CardRestartByAlarmAction
          selectedRow={selectedRow}></CardRestartByAlarmAction>
      </Grid>
    </Grid>
  );
}
